<template>
  <div class="sidebar"
       :data-color="backgroundColor">

    <div class="logo">
      <a href="#/dashboard" class="simple-text logo-mini">
        <div class="logo-image">
          <img :src="logo">
        </div>
      </a>

      <a href="#/dashboard" class="simple-text logo-normal" style="line-height: 20px;margin-top: -5px;" v-html="title">
        
      </a>
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-simple btn-icon btn-neutral btn-round" @click="minimizeSidebar">
          <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
          <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
        </button>
      </div>
    </div>
    <div class="facility" :class="$route.path.indexOf('fire')>=0?'fire':''">
      <ul class="clearfix" v-if="$route.path.indexOf('earthquake')>=0">
        <li><a href="/#/earthquake/building/city-info" :class="$route.path.indexOf('earthquake/building')>=0?'cur':''"><img src="img/building.ico" alt=""><span>建筑群落</span></a></li>
        <li><a href="/#/earthquake/traffic/basic" :class="$route.path.indexOf('earthquake/traffic')>=0?'cur':''"><img src="img/traffic.ico"  alt=""><span>路网交通</span></a></li>
        <li><a href="/#/earthquake/water/basic" :class="$route.path.indexOf('earthquake/water')>=0?'cur':''"><img src="img/waterconservation.ico" alt=""><span>城市水网</span></a></li>
        <li><a href="/#/earthquake/electric/basic" :class="$route.path.indexOf('earthquake/electric')>=0?'cur':''"><img src="img/electricity.ico" alt=""><span>城市电网</span></a></li>
      </ul>
      <ul class="clearfix" v-if="$route.path.indexOf('fire')>=0">
        <li><a href="/#/fire/building/city-info" :class="$route.path.indexOf('fire/building')>=0?'cur':''"><img src="img/building.ico" alt=""><span>建筑群落</span></a></li>
      </ul>
    </div>

    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" 
                        :key="link.name + index"
                        :link="link">

            <sidebar-item v-for="(subLink, index) in link.children"
                          :key="subLink.name + index"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
      <!-- <span @click="changeUI" class="changeUI">白/黑</span> -->
    </div>   
    
     <div class="left-tip-block" style="">
      <p class="left-tip-title" style=""><i class="now-ui-icons travel_info"></i> 说明</p>
      <div class="left-tip-content" id="left-tip-content" style="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: '韧性城市火灾风险<br>与消防安全系统'
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: value => {
        let acceptedValues = [
          '',
          'blue',
          'azure',
          'green',
          'orange',
          'red',
          'purple',
          'black',
          'white'
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: 'img/icon-ren.png'
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    changeUI(){
      this.backgroundColor= this.backgroundColor=='black'?'white':'black'
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    closeOthers(curid) {
     
      this.$children.forEach(element => {
        //debugger;
      });
      //this.$refs['sidebar-'+(curid-1)].closeMenu()
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>
.sidebar[data-color="white"] .nav li.active > a:not([data-toggle="collapse"]), .off-canvas-sidebar[data-color="white"] .nav li.active > a:not([data-toggle="collapse"]){
  color:#000;
}
.sidebar[data-color="white"] .nav li.active > a:not([data-toggle="collapse"]) i, .sidebar[data-color="white"] .nav li.active > a:not([data-toggle="collapse"]) .sidebar-mini-icon, .off-canvas-sidebar[data-color="white"] .nav li.active > a:not([data-toggle="collapse"]) i, .off-canvas-sidebar[data-color="white"] .nav li.active > a:not([data-toggle="collapse"]) .sidebar-mini-icon{
    color:#000;
}
.changeUI{
 
  padding-top: 50px;
  color: #fff;
  /* width: 100%; */
  display: block;
  padding-left: 20px;
}
.facility{
  display: block;position: relative;z-index: 999;margin-top: 15px;
}
.facility ul{list-style: none;padding: 0 10px;margin: 0;}
.facility li{
  float: left;
  width: 25%;
  
  font-size: 10px;
  text-align: center;
}
.facility li a{
  display: block;
  color:rgba(255,255,255,0.5);
}
.facility li a:hover{
  text-decoration: none;
}
.facility li img{
  width: 38px;
  display: block;
  margin: 0 auto 6px;
  opacity: 0.5;
}
.facility .cur {
  color:#fff;
}
.facility .cur img {
  opacity: 1;
}
.facility.fire {
  
}
/* .sidebar .nav li > a:not([data-toggle="collapse"]), .off-canvas-sidebar .nav li > a:not([data-toggle="collapse"]) {
    
}
.sidebar .links__nav .nav-link, .sidebar .nav li:first-child .nav-link {
  margin: 0 auto;
  width: 70%;
}
.sidebar-mini .sidebar .links__nav .nav-link{
  margin: auto;
  width: auto;
} */
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
  .left-tip-block{
    padding:20px 0px;
    color:#fff; 
    height:210px; 
    overflow:hidden;
    z-index:10;
    position: absolute;
    bottom: 20px;
    width: 100%;
    background: #1A2948;
  }

  .left-tip-title{
    margin:0px 15px; 
    line-height:14px;
    }
  .left-tip-content{
    height:180px; 
    margin:15px; 
    background:#303D4F; 
    padding:10px 10px 30px;
    overflow-y: auto;
  }
  .sidebar-mini .left-tip-block{
     opacity: 0;
  }
  .sidebar:hover .left-tip-block{
    opacity: 1;
  }
}
</style>
