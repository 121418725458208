<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{active : isActive}"
    tag="li"
    :ref="'sidebar-'+link.id"
    exact
  >
    <a
      v-if="isMenu"
      href="#"
      class="nav-link sidebar-menu-item"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <i :class="link.icon"></i>
      <p>
        {{link.name}}
        <b class="caret"></b>
      </p>
    </a>

    <collapse-transition>
      <div v-if="$slots.default || this.isMenu" v-show="!collapsed">
        <ul class="nav links__nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
      <component
        :to="link.path"
        @click.native="linkClick"
        :is="elementType(link, false)"
        :class="{active: link.active}"
        class="nav-link"
        :target="link.target"
        :href="link.path"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{filterText(linkPrefix,link.name)}}</span>
          <span class="sidebar-normal">{{link.name}}</span>
        </template>
        <template v-else>
          <i :class="link.icon"></i>
          <p>{{link.name}}</p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "sidebar-item",
  components: {
    CollapseTransition
  },
  props: {
    menu: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: "",
          path: "",
          children: []
        };
      }
    }
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true
    }
  },
  data() {
    return {
      children: [],
      collapsed: true
    };
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? "li" : "router-link";
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(" ");
        return words.map(word => word.substring(0, 1)).join("");
      }
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        // let matchingRoute;
        // if(!!this.$route.query.mid) {
        //   //mid是fire的量产页参数，有mid表示量产页
        //   matchingRoute = (!!this.link.mids && this.link.mids.indexOf(','+this.$route.query.mid+',')>-1)? true: undefined
        // } else {
        //   matchingRoute = this.children.find(c =>
        //     this.$route.path.startsWith(c.link.path)
        //   );
        // }
        let matchingRoute = this.children.find(c =>
          this.$route.path.startsWith(c.link.path)
        );
        
        if(!!this.link.mids && this.link.mids.indexOf(','+this.$route.params.mid+',')>-1) {
          //火灾量产页面
          return true;
        }
        if(!!this.link.emids && this.link.emids.indexOf(','+this.$route.params.emid+',')>-1) {
          //火灾韧性评估量产
          return true;
        }
        if(!!this.link.dmids && this.link.dmids.indexOf(','+this.$route.params.dmid+',')>-1) {
          //火灾韧性按维度评估量产
          return true;
        }
        if(!!this.link.smids && this.link.smids.indexOf(','+this.$route.params.smid+',')>-1) {
          //火灾韧性按维度评估量产
          return true;
        }
        if(!!this.link.dids && this.link.dids.indexOf(','+this.$route.params.did+',')>-1) {
          //火灾韧性决策
          return true;
        }
        if(!!this.link.sids && this.link.sids.indexOf(','+this.$route.params.sid+',')>-1) {
          //火灾韧性决策
          return true;
        }
        if(!!this.link.vids && this.link.vids.indexOf(','+this.$route.params.vid+',')>-1) {
          //火灾韧性决策-消防人员和装备配备优化
          return true;
        }
        if (matchingRoute !== undefined) {
          return true;
        }

      }
      return false;
    }
  },
  methods: {
    filterText(str,fullstr) {
      if (str == "抗") {
        return "烈";
      } else if (str == "修") {
        return "年";
      } else if (str == "重") {
        return "本";
      }  else {
        return str;
      }
      // else if(fullstr== '灾前建筑加固'){
      //   return "固"
      // } else if(fullstr== '灾后应急避难'){
      //   return "避"
      // } else if(fullstr== '灾后医疗救援'){
      //   return "医"
      // } else if(fullstr== '灾后中长期恢复'){
      //   return "恢"
      // }
      
      
    },
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join("");
    },
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
      if(!this.collapsed) {
        this.$parent.closeOthers(this.link.id)
      }
    },
    closeMenu() {
      this.collapsed = false;
      
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }

    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
    if(!!this.link.mids && this.link.mids.indexOf(','+this.$route.params.mid+',')>-1) {
      //火灾量产页面
      this.collapsed = false;
    }
    if(!!this.link.emids && this.link.emids.indexOf(','+this.$route.params.emid+',')>-1) {
      //火灾评估量产页面
      this.collapsed = false;
    }
    if(!!this.link.dmids && this.link.dmids.indexOf(','+this.$route.params.dmid+',')>-1) {
      //火灾韧性按维度评估量产
      this.collapsed = false;
    }
    if(!!this.link.smids && this.link.smids.indexOf(','+this.$route.params.smid+',')>-1) {
      //火灾韧性按维度评估量产
      this.collapsed = false;
    }
    if(!!this.link.dids && this.link.dids.indexOf(','+this.$route.params.did+',')>-1) {
      //火灾韧性决策
      this.collapsed = false;
    }
    if(!!this.link.sids && this.link.sids.indexOf(','+this.$route.params.sid+',')>-1) {
      //火灾韧性决策
      this.collapsed = false;
    }
    if(!!this.link.vids && this.link.vids.indexOf(','+this.$route.params.vid+',')>-1) {
      //火灾韧性决策-消防人员和装备配备优化
      this.collapsed = false;
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style>
.sidebar-menu-item {
  cursor: pointer;
}
.sidebar ul.links__nav {
  margin-top: 0;
  padding-top: 10px;
}
</style>
