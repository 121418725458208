<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {};
</script>
<style lang="scss">
a:not([href]),
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

html,
body {
  height: 100vh;
  overflow: hidden;
}

// 火狐滚动条
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0,0,0,0.3) transparent;
}
// 谷歌滚动条
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:hover {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: rgba(0,0,0,0.3);
}

/**/
.card-map {
  min-height: 350px;
  height: calc(100vh - 48px);
  margin-bottom: 0;
  position: relative;

  .map-wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;

    &>* {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  #map {
    width: 100%;
    height: calc(100vh - 100px);
  }
}

.show_list {
  // width: 100%;
  // height: 30px;
  // margin-top: 8px;
  width: 100%;
  height: 50px;
  padding: 6px 15px 0;
}

.show_list span {
  height: 32px;
  line-height: 36px;
  margin-left: 32px;
  font-size: 14px;
}

.show_list span i {
  height: 16px;
  padding: 2px 12px;
  background: #ffb236;
  margin: 0 4px;
  border-radius: 16px;
}

.show_list .up_show {
  display: block;
  float: left;
  font-size: 32px;
  cursor: pointer;
}

.show_list .download {
  display: block;
  float: right;
  font-size: 32px;
  cursor: pointer;
}

.footer {
  padding-top: 0;
}

.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css");

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

ul.nav__items>li:last-of-type a {
  margin-right: 0 !important;
}

#customStyle .el-collapse-item .el-select {
  width: 200px;
}



#customStyle .el-collapse-item .el-icon-refresh-left {
  position: absolute;
  top: 14px;
  left: 220px;
  font-size: 25px;
  color: #BEC3C7;
}

@media (max-width: 1300px) {
  #customStyle .el-collapse-item .el-select {
    width: 80%;
  }

  #customStyle .el-collapse-item .el-icon-refresh-left {
    left: 80%;
  }
}

.mapboxgl-canvas {
  outline: none;
}

.disaster-selecter {
  width: 140px;
  height: 80px;
  background: url(/img/icons/icon-disaster.png) no-repeat;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: 10px;

  select {
    width: 115px;
    border: 0;
    position: absolute;
    left: 20px;
    top: 45px;
    outline: none;
    // background: url(/img/icons/icon-disaster-dot.png) no-repeat;
    // background-size: contain;
    background: transparent;
    font-size: 12px;
  }
}

.weather-selecter {
  width: 210px;
  height: 212px;
  background: url(/img/icons/icon-weather.png) no-repeat;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: 60px;
  overflow: hidden;
  transition: height .4s ease-in;
  z-index: 1;

  .weather-selecter-header {
    width: 100%;
    height: 50px;
    cursor: pointer;
  }

  &.full {
    height: 520px;
  }

  table {
    width: 206px;
    border: 0;
    position: absolute;
    right: 0;
    top: 45px;
    outline: none;
    // background: url(/img/icons/icon-disaster-dot.png) no-repeat;
    // background-size: contain;
    background: transparent;
    font-size: 12px;

    tr:nth-of-type(2n) {
      background: #b1faff;
    }

    tr:nth-of-type(2n+1) {
      background: #e1fdff;
    }

    td {
      padding-left: 5px;
      line-height: 22px;
    }

    td:nth-of-type(2n+1) {
      width: 130px;
    }
  }
}

/**/
.show_list {
  // padding: 0 15px;
}

.show_list_record {
  /*position: relative;*/
  width: 100%;
  position: absolute;
  bottom: 50px;
  display: block;
  z-index: 999;
  background: #394761;
}

.el-table__body tr:hover>td.el-table__cell {
  background: transparent !important;
}

.show_list_record .el-table th,
.show_list_record .el-table tr {
  background: #394761 !important;
  color: #fff !important;
}

.show_list_record .tab-space {
  padding: 0;
}

.category,
.card-category {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #fff;
}

.el-table__header div {
  color: #f96332
}

.show_list_record .el-table .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.show_fire_list_record .el-table .cell {
  overflow: auto;
  text-overflow: initial;
  white-space: inherit;
}

.show_list_record .el-table th>.cell {
  font-size: 14px;
  font-weight: bold;
}

.table>thead>tr>th,
.el-table table>thead>tr>th,
.table>tbody>tr>th,
.el-table table>tbody>tr>th,
.table>tfoot>tr>th,
.el-table table>tfoot>tr>th,
.table>thead>tr>td,
.el-table table>thead>tr>td,
.table>tbody>tr>td,
.el-table table>tbody>tr>td,
.table>tfoot>tr>td,
.el-table table>tfoot>tr>td {
  padding: 7px 5px;
}

.show_list_record .card-category {
  line-height: 46px;
  margin-bottom: 0;
}

.show_list_record .pagination {
  position: relative;
  top: 8px;
}

.card-body .nav__items li {
  display: inline-block;
}

.nav-pills.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
  margin-right: 0;
}

.card-body .nav__items li a {
  padding: 6px 6px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  min-width: auto !important;
}

.card-body ul.nav__items {
  border-bottom: 3px solid rgb(9, 168, 169);
}

.panel-header {
  background: #223057 !important;
}

.nav-pills.nav-pills-primary .nav-item .nav-link.active,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
  background-color: #BEC3C7;
  color: #394761;
}

.tab-space .bootstrap-switch {
  margin-bottom: 0;
  float: right;
}

.tab-space .switch-label {
  color: #2c2c2c;
}

.data-filter .col-md-12 {
  margin-bottom: 10px;
}

.tab-space .el-select {
  display: block;
}

.tab-space .el-select .el-input .el-input__inner {
  margin: 0 0 5px;
}

#customStyle .tab-space {
  padding-bottom: 0;
  background: #394761;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  display: block;
  overflow: hidden;
  clear: both;
  height: 0;
  visibility: hidden;
  content: ".";
}

#customStyle {
  padding: 0;
  height: calc(100vh - 53px);
  overflow-y: auto;
}

/*#customStyle{
  padding: 0;
  height: calc(100vh - 53px);
  overflow: inherit!important;
}
.el-collapse-item__wrap {
  overflow: inherit!important;
}*/
/*设置颜色*/
@media (min-width: 991px) {
  .sidebar {
    background: #1A2948;
  }
}

.form-check-radio input[type="radio"]:checked+.form-check-sign::after {
  background-color: #fff;
  border-color: #fff;
}

.show_list {
  color: #fff;
}

.card label {
  color: #fff !important;
}

#customStyle {
  background: #303D4F;
  color: #fff;
}

#mapboxgl-minimap {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.main-panel>.content,
#customStyle .card {
  background: #303D4F;
  margin-bottom: 0;
}

.nav-pills .nav-item .nav-link {
  color: #BEC3C7;
  background: transparent;
}

.card-map,
#customStyle .card-body {
  /* background: rgba(255,255,255,0.2); */
  background: #303D4F;
}

.card-map .card-body {
  padding: 0;
}

.show_list span i {
  background: #fff !important;
  color: #2f3d4e;
}

.btn-base,
.el-select .el-input .el-input__inner,
.btn,
.navbar .navbar-nav>a.btn {
  background: #BEC3C7;
  color: #394761;
}

#customStyle .form-check .form-check-sign::after {
  color: #fff;
}

.sidebar-menu-item {
  background: #1d2946;
  box-shadow: 0 0 2px #ccc;
}

#customStyle .card-body {
  height: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;
}

#customStyle .card-body>div {
  height: 100%;
}

.right-block .tab-pane {
  height: 100%;
  overflow: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

#customStyle .dataset input {
  margin: 5px 0 10px;
  border-radius: 0;
  background: #fff;
  border: 1px solid #999;
  padding: 5px 22px;
  height: 34px;
}

#customStyle .dataset .el-select {
  display: block;
}

#customStyle .dataset input:hover {
  box-shadow: none;
}

#customStyle .dataset .el-select .el-input .el-select__caret {
  color: #888 !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.loading {
  position: absolute;
  left: 50%;
  top: 45%;
  margin-left: -38px;
  z-index: 999;
  animation: rotation 2s linear 0s infinite;
  -webkit-animation: rotation 2s linear 0s infinite;
  -o-animation: rotation 2s linear 0s infinite;
  -moz-animation: rotation 2s linear 0s infinite;
}

.sidebar-mini .sidebar {
  overflow: auto;
  background: #1A2948;
}

.sidebar[data-color="black"]:after,
.off-canvas-sidebar[data-color="black"]:after {
  background: none;
}

.map_data_msg {
  position: absolute;
  left: 20px;
  bottom: 70px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  // color: #02f4fe;
  color: #fff;
  padding: 10px;
  width: auto;

  .map_data_legend {
    margin-bottom: 5px;
  }

  .map_data_msg_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .placeholder {
      opacity: 0;
    }

    .item {
      // width: 80px;
      line-height: 15px;
      padding: 5px 0;
    }

    .item span {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 3px;
      vertical-align: middle;
      margin-left: 0;
    }

    .item span.round {
      border-radius: 100%;
    }

    .item span.rect {
      width: 30px;
    }

    .item span.line {
      width: 70px;
      height: 5px;
    }

    .item .figure {
      width: 30px;
    }

    .map_data_msg_inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 10px;
    }
  }

  .map_data_msg_wrapper_vertical {
    flex-direction: column;

    .item {
      width: 130px;
    }
  }
}

.key-infos {
  position: absolute;
  right: 20px;
  top: 16px;
  color: #fff;
}

.show_list .download {
  color: #fff;
}

.el-collapse {
  margin: 15px;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.el-collapse-item__header {
  height: 34px !important;
  line-height: 34px !important;
}

.el-collapse-item__header,
.el-collapse-item__wrap,
.el-collapse-item__content {
  background: transparent !important;
  color: #fff !important;
  border-bottom: 0 !important;
}

.el-collapse-item__content {
  padding-top: 20px;
}

.el-collapse-item__content .col-md-12 {
  padding: 0;
}

.el-collapse-item.is-active [role='tab'] {
  background: #789da5;

}

.el-collapse-item [role='tab'] {
  padding-left: 10px;
  border-bottom: 1px solid #09a8a9;
}

.noUi-target {
  background-color: rgba(182, 182, 182, 1);
}

.noUi-horizontal {
  height: 4px;
}

.key-decision {
  position: fixed;
  background: #303D4F;
  z-index: 19999999;
  top: 47px;
  height: 70px;
  border-bottom: 3px solid #09a8a9;
  padding-top: 9px;
}

.hasDecision {
  padding-top: 70px !important;
}

.el-input input {
  margin: 5px 1px !important;
}

.map-popup {
  z-index: 1;
}

.map-popup .mapboxgl-popup-content {
  /*background: rgba(0, 0, 0, 0.5);*/
  background: rgba(38, 46, 93, 0.9);
  color: #fff;
  border: 2px solid #04bbc7;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgba(0, 0, 0, 0.5) !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: rgba(0, 0, 0, 0.5) !important;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
}

.mapboxgl-popup-close-button {
  color: #fff !important;
  font-size: 20px;
}

.chart-area {
  width: 100%;
  margin-bottom: 20px;
}

.el-select-dropdown {
  z-index: 29999999 !important;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.el-select .el-select__tags .el-tag {
  color: #394761 !important;
}

::-webkit-input-placeholder {
  color: #394761 !important;
}

/*switch*/
.switch-two-side,
.switch-one-side {
  position: absolute;
  z-index: 2;
  left: 30px;
  top: 18px;
  display: flex;
  color: #fff;

  p {
    padding: 0 5px;
  }
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  background: #fff;
}

.bootstrap-switch.bootstrap-switch-off {
  background: rgba(44, 44, 44, 1);

}

.bootstrap-switch.bootstrap-switch-on {
  background: #789da5;
}

.switch-two-side .bootstrap-switch {
  background: #789da5;
}

.sub-item {
  margin: 10px 0 10px 40px;
}

.sub-item a {
  color: #fff;
  text-decoration: none;
}

.sub-item {
  color: #fff;
  text-decoration: none;
}

.sub-item div {
  opacity: 0.5
}

.sub-item div.cur {
  opacity: 1;
}

.sidebar-mini .sub-item {
  display: none;
}

.sidebar-mini .sidebar:hover .sub-item {
  display: block;
}

.sub-item div:before {
  content: "●";
  color: #fff;
  font-size: 24px;
  vertical-align: -3px;
  padding-right: 10px;
}
</style>
