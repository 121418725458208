<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      
       <ul class="navbar-nav" >
        <li style="margin-right:2px;"><span class="navbar-brand" style="margin-right:0px;"><i style="font-style: normal; color:#999;">城市</i> {{city_name}}</span></li>
        <drop-down tag="li"
                   position="left"
                   class="nav-item"
                   >

          <a class="dropdown-item" @click="setCityID(idx)" v-for="(item,idx) in cities">{{item.name}}</a>
        </drop-down>
        <li style="margin-left:20px; margin-right:2px;"><span class="navbar-brand" style="margin-right:0px;"><i style="font-style: normal; color:#999;">灾害</i> {{disaster_name}}</span></li>
        <drop-down tag="li"
                   position="left"
                   class="nav-item"
                   >

          <a class="dropdown-item" @click="setDisasters(idx)" v-for="(item,idx) in disasters"><img :src="'img/disasters/'+(idx+1)+'.ico'">{{item.name}}</a>
        </drop-down>
        <li class="nav-item logout">
        <a class="nav_link" @click="logout">登出</a>
      </li> 

        
      </ul>
      <!-- <div class="nav-item logout pull-right">
        <a class="nav_link" @click="logout">登出</a>
      </div>  -->


    </div>
    <button @click="toggleNavbar" class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template slot="navbar-menu">

      

      <!-- <form>
        <div class="input-group no-border">
          <fg-input placeholder="搜索..." addon-right-icon="now-ui-icons ui-1_zoom-bold">
          </fg-input>
        </div>
      </form> -->

      <ul class="navbar-nav">
        
        <!-- <drop-down tag="li"
                   position="right"
                   class="nav-item"
                   icon="now-ui-icons users_single-02">

          <a class="dropdown-item" href="#">个人资料</a>
          <a class="dropdown-item" href="#">修改密码</a>
          <a class="dropdown-item" href="#">退出</a>
        </drop-down> -->

        <!-- <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li> -->
      </ul>

    </template>
  </navbar>
</template>
<script>
import { RouteBreadCrumb, Navbar, NavbarToggleButton } from 'src/components';
import { CollapseTransition } from 'vue2-transitions';

export default {
  components: {
    RouteBreadCrumb,
    Navbar,
    NavbarToggleButton,
    CollapseTransition
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    cities() {
      return JSON.parse(sessionStorage.getItem('cities'))
    },
    disasters() {
      return JSON.parse(sessionStorage.getItem('disasters'))
    },
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      city_name: localStorage.getItem('city_name') || JSON.parse(sessionStorage.getItem('cities'))[0].name,
      city_id: localStorage.getItem('city_id') || JSON.parse(sessionStorage.getItem('cities'))[0].id,
      disaster_name: localStorage.getItem('disaster_name') || JSON.parse(sessionStorage.getItem('disasters'))[0].name,
      disaster_id: localStorage.getItem('disaster_id') || JSON.parse(sessionStorage.getItem('disasters'))[0].id
      
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    setCityID (idx) {
      if(idx > 0) {
        this.$notify({
            message: '该城市暂未开放',
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          })
        return;
      }
      localStorage.setItem('city_id', this.cities[idx].id)
      localStorage.setItem('city_name', this.cities[idx].name)
      this.city_name = this.cities[idx].name
      this.city_id = this.cities[idx].id
      this.$router.replace({
        path: '/refresh'
      })
    },
    setDisasters (idx) {
      if(idx == 1) {
         this.$notify({
            message: '该灾害类型暂未开放',
            icon: 'fa fa-gift',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          })
        return;
      }
      localStorage.setItem('disaster_id', this.disasters[idx].id)
      localStorage.setItem('disaster_name', this.disasters[idx].name)
      this.disaster_name = this.disasters[idx].name
      this.disaster_id = this.disasters[idx].id
      if(idx == 0) {
        //earthquake
        this.$router.replace({
          path: '/earthquake/building/city-info'
        })
      } else if(idx == 2) {
        this.$router.replace({
          path: '/fire/building/alarm/1'
        })
      }
      
    },
    logout () {
      sessionStorage.removeItem('AccessToken')
      localStorage.removeItem('disaster_id')
      localStorage.removeItem('disaster_name')
      this.$router.replace('/login')
    }
  }
};
</script>
<style>
.logout {
  /* position: absolute;
  right: 20px; 
  line-height: 40px;
  cursor: pointer;
  position: relative;
  top: 4px;*/
  cursor: pointer;
    position: absolute;
    top: 15px;
    left: 240px;
}
</style>
