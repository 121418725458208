import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Dashboard/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Page Headers
import CalendarHeader from "src/pages/Dashboard/Calendar/CalendarHeader";
const DashboardHeader = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/DashboardHeader.vue"
  );
import DefaultHeader from "src/pages/Dashboard/DefaultHeader";
import SweetAlertHeader from "src/pages/Dashboard/Components/Headers/SweetAlertHeader";
import VectorMapsHeader from "src/pages/Dashboard/Maps/VectorMapsHeader";

// Home pages
//const HomeMaps = () => import( /* webpackChunkName: "home" */ 'src/pages/Dashboard/Home/Home.vue');

// Calendar
const Calendar = () =>
  import(
    /* webpackChunkName: "calendar" */ "src/pages/Dashboard/Calendar/CalendarRoute.vue"
  );
// Charts
const Charts = () =>
  import(/* webpackChunkName: "charts" */ "src/pages/Dashboard/Charts.vue");

// Components pages
const Buttons = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Buttons.vue"
  );
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/GridSystem.vue"
  );
const Panels = () =>
  import(
    /* webpackChunkNcame: "components" */ "src/pages/Dashboard/Components/Panels.vue"
  );
const SweetAlert = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/SweetAlert.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Notifications.vue"
  );
const Icons = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Icons.vue"
  );
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Typography.vue"
  );

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
  );
import Widgets from "src/pages/Dashboard/Widgets.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// Maps pages
const GoogleMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/GoogleMaps.vue"
  );
const FullScreenMap = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/FullScreenMap.vue"
  );
const VectorMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/VectorMaps.vue"
  );

// Pages
const User = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/UserProfile.vue"
  );
const Pricing = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/Pricing.vue"
  );
const TimeLine = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/TimeLinePage.vue"
  );
const Login = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Login.vue");
const Register = () =>
  import(
    /* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Register.vue"
  );
const Lock = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Lock.vue");

// TableList pages
const RegularTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/RegularTables.vue"
  );
const ExtendedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/ExtendedTables.vue"
  );
const PaginatedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/PaginatedTables.vue"
  );
const CityInfo = () => import("src/pages/Dashboard/Home/CityInfo.vue");
const Disaster = () => import("src/pages/Dashboard/Home/Disaster.vue");
const BuildingBuildYear = () =>
  import("src/pages/Dashboard/Building/BuildYear.vue");
const BuildingCategory = () =>
  import("src/pages/Dashboard/Building/Category.vue");
const BuildingQuakeLevel = () =>
  import("src/pages/Dashboard/Building/QuakeLevel.vue");
const BuildingRebuildCost = () =>
  import("src/pages/Dashboard/Building/RebuildCost.vue");
const BuildingStruct = () => import("src/pages/Dashboard/Building/Struct.vue");
const AssessEconomicLoss = () =>
  import("src/pages/Dashboard/Assess/EconomicLoss.vue");
const AssessPhysicalLoss = () =>
  import("src/pages/Dashboard/Assess/PhysicalLoss.vue");
const AssessQuake = () => import("src/pages/Dashboard/Assess/Quake.vue");
const AssessRecovery = () => import("src/pages/Dashboard/Assess/Recovery.vue");
// const AssessRecovery2 = () =>
// import('src/pages/Dashboard/Assess/Recovery2.vue');
const AssessComprehensiveRisk = () =>
  import("src/pages/Dashboard/Assess/ComprehensiveRisk.vue");
const DecisionReinforce = () =>
  import("src/pages/Dashboard/Decision/Reinforce.vue");
const DecisionShelter = () =>
  import("src/pages/Dashboard/Decision/Shelter.vue");
const DecisionRescue = () => import("src/pages/Dashboard/Decision/Rescue.vue");
const DecisionRecovery = () =>
  import("src/pages/Dashboard/Decision/Recovery.vue");
// const DecisionRecovery2 = () => import('src/pages/Dashboard/Decision/Recovery2.vue');
const BuildingRisk = () => import("src/pages/Dashboard/Assess/risk.vue");

const WaterBasic = () => import("src/pages/Dashboard/Water/Basic.vue");
const WaterNode = () => import("src/pages/Dashboard/Water/Node.vue");
const WaterPipe = () => import("src/pages/Dashboard/Water/Pipe.vue");
const WaterVolumn = () => import("src/pages/Dashboard/Water/Volumn.vue");
const WaterPhysicalLoss = () =>
  import("src/pages/Dashboard/Water/PhysicalLoss.vue");
const WaterFunctionLoss = () =>
  import("src/pages/Dashboard/Water/FunctionLoss.vue");
const WaterSignificance = () =>
  import("src/pages/Dashboard/Water/Significance.vue");
const WaterRisk = () => import("src/pages/Dashboard/Water/risk.vue");
const WaterReinforce = () => import("src/pages/Dashboard/Water/Reinforce.vue");
const WaterRecovery = () => import("src/pages/Dashboard/Water/Recovery.vue");

const ElectricBasic = () => import("src/pages/Dashboard/Electric/Basic.vue");
const ElectricNode = () => import("src/pages/Dashboard/Electric/Node.vue");
const ElectricPipe = () => import("src/pages/Dashboard/Electric/Pipe.vue");
const ElectricVolumn = () => import("src/pages/Dashboard/Electric/Volumn.vue");
const ElectricPhysicalLoss = () =>
  import("src/pages/Dashboard/Electric/PhysicalLoss.vue");
const ElectricFunctionLoss = () =>
  import("src/pages/Dashboard/Electric/FunctionLoss.vue");
const ElectricSignificance = () =>
  import("src/pages/Dashboard/Electric/Significance.vue");
const ElectricRisk = () => import("src/pages/Dashboard/Electric/risk.vue");
const ElectricReinforce = () =>
  import("src/pages/Dashboard/Electric/Reinforce.vue");
const ElectricRecovery = () =>
  import("src/pages/Dashboard/Electric/Recovery.vue");

const TrafficBasic = () => import("src/pages/Dashboard/Traffic/Basic.vue");
const TrafficNode = () => import("src/pages/Dashboard/Traffic/Node.vue");
const TrafficPipe = () => import("src/pages/Dashboard/Traffic/Pipe.vue");
const TrafficVolumn = () => import("src/pages/Dashboard/Traffic/Volumn.vue");
const TrafficPhysicalLoss = () =>
  import("src/pages/Dashboard/Traffic/PhysicalLoss.vue");
const TrafficJam = () => import("src/pages/Dashboard/Traffic/Jam.vue");
const TrafficEfficiency = () =>
  import("src/pages/Dashboard/Traffic/Efficiency.vue");

const TrafficFunctionLoss = () =>
  import("src/pages/Dashboard/Traffic/FunctionLoss.vue");
const TrafficSignificance = () =>
  import("src/pages/Dashboard/Traffic/Significance.vue");
const TrafficRisk = () => import("src/pages/Dashboard/Traffic/risk.vue");
const TrafficReinforce = () =>
  import("src/pages/Dashboard/Traffic/Reinforce.vue");
const TrafficRecovery = () =>
  import("src/pages/Dashboard/Traffic/Recovery.vue");

const FireAlarmStatistics = () =>
  import("src/pages/Dashboard/Fire/Building/AlarmStatistics.vue");
const FireDisasterStatistics = () =>
  import("src/pages/Dashboard/Fire/Building/DisasterStatistics.vue");
const FireDeathStatistics = () =>
  import("src/pages/Dashboard/Fire/Building/DeathStatistics.vue");
const FireInjuryStatistics = () =>
  import("src/pages/Dashboard/Fire/Building/InjuryStatistics.vue");
const FireLossStatistics = () =>
  import("src/pages/Dashboard/Fire/Building/LossStatistics.vue");

const Fire2AlarmStatistics = () =>
  import("src/pages/Dashboard/Fire2/Building/alarm.vue");
const Fire2Electricity = () =>
  import("src/pages/Dashboard/Fire2/Building/electricity.vue");

const Fire2FireProofingStatistics = () =>
  import("src/pages/Dashboard/Fire2/Building/fireproofing.vue");
const Fire2Firefighter = () =>
  import("src/pages/Dashboard/Fire2/Building/firefighter.vue");
const Fire2Training = () =>
  import("src/pages/Dashboard/Fire2/Building/training.vue");
const Fire2FirefighterCommunity = () =>
  import("src/pages/Dashboard/Fire2/Building/firefighterCommunity.vue");
const Fire2TrainingCommunity = () =>
  import("src/pages/Dashboard/Fire2/Building/trainingCommunity.vue");

const Fire2Prevent = () =>
  import("src/pages/Dashboard/Fire2/Building/prevent.vue");
const Fire2AllProcess = () =>
  import("src/pages/Dashboard/Fire2/Building/allprocess.vue");
const Fire2AllProcessCity = () =>
  import("src/pages/Dashboard/Fire2/Building/allprocessCity.vue");
const Fire2Category = () =>
  import("src/pages/Dashboard/Fire2/Building/Category.vue");
// const Fire2PreventCommunity = () => import('src/pages/Dashboard/Fire2/Building/preventCommunity.vue');
// const Fire2AllProcessCommunity = () => import('src/pages/Dashboard/Fire2/Building/allprocessCommunity.vue');

const Fire2Tp = () => import("src/pages/Dashboard/Fire2/Building/tp.vue");
const Fire2ETp = () => import("src/pages/Dashboard/Fire2/Building/etp.vue");
const Fire2DTp = () => import("src/pages/Dashboard/Fire2/Building/dtp.vue");
const Fire2STp = () => import("src/pages/Dashboard/Fire2/Building/stp.vue");
const Fire2Stationcost = () =>
  import("src/pages/Dashboard/Fire2/Building/stationcost.vue");
const Fire2StationGrids = () =>
  import("src/pages/Dashboard/Fire2/Building/stationgrids.vue");
const Fire2StationDuration = () =>
  import("src/pages/Dashboard/Fire2/Building/stationduration.vue");
const Fire2StationLayouts = () =>
  import("src/pages/Dashboard/Fire2/Building/stationlayouts.vue");
const StationArrive = () =>
  import("src/pages/Dashboard/Fire2/Building/stationArrive.vue");
const Fire2Vehicles = () =>
  import("src/pages/Dashboard/Fire2/Building/vehicles.vue");
const Fire2Equipments = () =>
  import("src/pages/Dashboard/Fire2/Building/equipments.vue");
const Fire2Dimensions = () =>
  import("src/pages/Dashboard/Fire2/Building/dimensions.vue");
const Fire2DecisionRisk = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionRisk.vue");
const Fire2DecisionScores = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionScore.vue");
const Fire2DecisionForecast = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionForecast.vue");
const Fire2DecisionDuration = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionDuration.vue");
const Fire2DecisionTotalDuration = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionTotalDuration.vue");
const Fire2DecisionLayoutOptimization = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionLayoutOptimization.vue");
const Fire2DecisionLayoutMoreOptimization = () =>
  import(
    "src/pages/Dashboard/Fire2/Building/decisionLayoutMoreOptimization.vue"
  );
const Fire2DecisionLayoutFullCoverOptimization = () =>
  import(
    "src/pages/Dashboard/Fire2/Building/decisionLayoutFullCoverOptimization.vue"
  );
const Fire2DecisionLayoutBalanceOptimization = () =>
  import(
    "src/pages/Dashboard/Fire2/Building/decisionLayoutBalanceOptimization.vue"
  );
const Fire2DecisionVehicleOptimization = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionVehicleOptimization.vue");
const Fire2DecisionPeopleOptimization = () =>
  import("src/pages/Dashboard/Fire2/Building/decisionPersonOptimization.vue");
const Fire2DecisionEquipmentOptimization = () =>
  import(
    "src/pages/Dashboard/Fire2/Building/decisionEquipmentOptimization.vue"
  );

const FirePopulation = () =>
  import("src/pages/Dashboard/Fire/Building/Population.vue");
const FireProduce = () =>
  import("src/pages/Dashboard/Fire/Building/Produce.vue");
const FireLand = () => import("src/pages/Dashboard/Fire/Building/Land.vue");
const FireIndustry = () =>
  import("src/pages/Dashboard/Fire/Building/Industry.vue");
const FirePower = () => import("src/pages/Dashboard/Fire/Building/Power.vue");
const FireUnitRisk = () =>
  import("src/pages/Dashboard/Fire/Building/UnitRisk.vue");
const FireUnitKey = () =>
  import("src/pages/Dashboard/Fire/Building/UnitKey.vue");
const FireTest = () => import("src/pages/Dashboard/Fire/Building/test.vue");
const FireHistory = () =>
  import("src/pages/Dashboard/Fire/Building/history.vue");

const refresh = () => import("src/pages/Dashboard/refresh.vue");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons, header: DefaultHeader },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem, header: DefaultHeader },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels, header: DefaultHeader },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert, header: SweetAlertHeader },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications, header: DefaultHeader },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons, header: DefaultHeader },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography, header: DefaultHeader },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms, header: DefaultHeader },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms, header: DefaultHeader },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard, header: DefaultHeader },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables, header: DefaultHeader },
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables, header: DefaultHeader },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps, header: DefaultHeader },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps, header: VectorMapsHeader },
    },
  ],
};

let earthquakeBuildingMenu = {
  path: "/earthquake/building",
  component: DashboardLayout,
  name: "building",
  redirect: "/earthquake/building/city-info",
  children: [
    // {
    //     path: 'home',
    //     name: 'Home page',
    //     components: { default: HomeMaps, header: DefaultHeader }
    // },
    {
      path: "city-info",
      name: "City Info",
      components: { default: CityInfo, header: DefaultHeader },
    },
    {
      path: "disaster",
      name: "Disaster",
      components: { default: Disaster, header: DefaultHeader },
    },
    {
      path: "struct",
      name: "Building struct",
      components: { default: BuildingStruct, header: DefaultHeader },
    },
    {
      path: "category",
      name: "Building category",
      components: { default: BuildingCategory, header: DefaultHeader },
    },
    {
      path: "quake-level",
      name: "Building quake level",
      components: { default: BuildingQuakeLevel, header: DefaultHeader },
    },
    {
      path: "build-year",
      name: "Building build year",
      components: { default: BuildingBuildYear, header: DefaultHeader },
    },
    {
      path: "rebuild-cost",
      name: "Building rebuild cost",
      components: { default: BuildingRebuildCost, header: DefaultHeader },
    },
    {
      path: "quake",
      name: "Assess quake",
      components: { default: AssessQuake, header: DefaultHeader },
    },
    {
      path: "physical-loss",
      name: "Physical loss",
      components: { default: AssessPhysicalLoss, header: DefaultHeader },
    },
    {
      path: "economic-loss",
      name: "Economic loss",
      components: { default: AssessEconomicLoss, header: DefaultHeader },
    },
    {
      path: "comprehensive-risk",
      name: "Comprehensive risk",
      components: { default: AssessComprehensiveRisk, header: DefaultHeader },
    },
    {
      path: "function-recovery",
      name: "function Recovery",
      components: { default: AssessRecovery, header: DefaultHeader },
    },
    // {
    //     path: 'function-recovery2',
    //     name: 'function Recovery',
    //     components: { default: AssessRecovery2, header: DefaultHeader }
    // },
    {
      path: "risk",
      name: "function Risk",
      components: { default: BuildingRisk, header: DefaultHeader },
    },
    {
      path: "reinforce",
      name: "reinforce",
      components: { default: DecisionReinforce, header: DefaultHeader },
    },
    {
      path: "shelter",
      name: "shelter",
      components: { default: DecisionShelter, header: DefaultHeader },
    },
    {
      path: "rescue",
      name: "Rescue",
      components: { default: DecisionRescue, header: DefaultHeader },
    },
    {
      path: "recovery",
      name: "Recovery",
      components: { default: DecisionRecovery, header: DefaultHeader },
    },
    // {
    //     path: 'recovery2',
    //     name: 'Recovery',
    //     components: { default: DecisionRecovery2, header: DefaultHeader }
    // },
  ],
};
//water
let earthquakeWaterMenu = {
  path: "/earthquake/water",
  component: DashboardLayout,
  name: "water",
  redirect: "/earthquake/water/basic",
  children: [
    {
      path: "basic",
      name: "waterBasic",
      components: { default: WaterBasic, header: DefaultHeader },
    },
    {
      path: "node",
      name: "waterNode",
      components: { default: WaterNode, header: DefaultHeader },
    },
    {
      path: "pipe",
      name: "waterPipe",
      components: { default: WaterPipe, header: DefaultHeader },
    },
    {
      path: "volumn",
      name: "waterVolumn",
      components: { default: WaterVolumn, header: DefaultHeader },
    },
    {
      path: "physical-loss",
      name: "WaterPhysicalLoss",
      components: { default: WaterPhysicalLoss, header: DefaultHeader },
    },
    {
      path: "function-loss",
      name: "WaterFunctionLoss",
      components: { default: WaterFunctionLoss, header: DefaultHeader },
    },
    {
      path: "significance",
      name: "WaterSignificance",
      components: { default: WaterSignificance, header: DefaultHeader },
    },
    {
      path: "risk",
      name: "WaterRisk",
      components: { default: WaterRisk, header: DefaultHeader },
    },
    {
      path: "reinforce",
      name: "WaterReinforce",
      components: { default: WaterReinforce, header: DefaultHeader },
    },
    {
      path: "recovery",
      name: "WaterRecovery",
      components: { default: WaterRecovery, header: DefaultHeader },
    },
  ],
};
//electric
let earthquakeElectricMenu = {
  path: "/earthquake/electric",
  component: DashboardLayout,
  name: "electric",
  redirect: "/earthquake/electric/basic",
  children: [
    {
      path: "basic",
      name: "electricBasic",
      components: { default: ElectricBasic, header: DefaultHeader },
    },
    {
      path: "node",
      name: "electricNode",
      components: { default: ElectricNode, header: DefaultHeader },
    },
    {
      path: "pipe",
      name: "electricPipe",
      components: { default: ElectricPipe, header: DefaultHeader },
    },
    {
      path: "volumn",
      name: "electricVolumn",
      components: { default: ElectricVolumn, header: DefaultHeader },
    },
    {
      path: "function-loss",
      name: "electricFunctionLoss",
      components: { default: ElectricFunctionLoss, header: DefaultHeader },
    },
    {
      path: "significance",
      name: "electricSignificance",
      components: { default: ElectricSignificance, header: DefaultHeader },
    },
    {
      path: "risk",
      name: "electricRisk",
      components: { default: ElectricRisk, header: DefaultHeader },
    },
    {
      path: "reinforce",
      name: "electricReinforce",
      components: { default: ElectricReinforce, header: DefaultHeader },
    },
    {
      path: "recovery",
      name: "electricRecovery",
      components: { default: ElectricRecovery, header: DefaultHeader },
    },
  ],
};
//traffic
let earthquakeTrafficMenu = {
  path: "/earthquake/traffic",
  component: DashboardLayout,
  name: "traffic",
  redirect: "/earthquake/traffic/basic",
  children: [
    {
      path: "basic",
      name: "trafficBasic",
      components: { default: TrafficBasic, header: DefaultHeader },
    },
    {
      path: "node",
      name: "trafficNode",
      components: { default: TrafficNode, header: DefaultHeader },
    },
    {
      path: "pipe",
      name: "trafficPipe",
      components: { default: TrafficPipe, header: DefaultHeader },
    },
    {
      path: "volumn",
      name: "trafficVolumn",
      components: { default: TrafficVolumn, header: DefaultHeader },
    },
    {
      path: "physical-loss",
      name: "trafficPhysicalLoss",
      components: { default: TrafficPhysicalLoss, header: DefaultHeader },
    },
    {
      path: "jam",
      name: "trafficJam",
      components: { default: TrafficJam, header: DefaultHeader },
    },
    {
      path: "efficiency",
      name: "trafficEfficiency",
      components: { default: TrafficEfficiency, header: DefaultHeader },
    },
    {
      path: "significance",
      name: "trafficSignificance",
      components: { default: TrafficSignificance, header: DefaultHeader },
    },
    {
      path: "risk",
      name: "trafficRisk",
      components: { default: TrafficRisk, header: DefaultHeader },
    },
    {
      path: "reinforce",
      name: "trafficReinforce",
      components: { default: TrafficReinforce, header: DefaultHeader },
    },
    {
      path: "recovery",
      name: "trafficRecovery",
      components: { default: TrafficRecovery, header: DefaultHeader },
    },
  ],
};
/*fire*/
let fireBuildingMenu = {
  path: "/fire/building",
  component: DashboardLayout,
  name: "fire",
  redirect: "/fire/building/alarm",
  children: [
    {
      path: "alarm/:mid",
      name: "fire alarm",
      components: { default: Fire2AlarmStatistics, header: DefaultHeader },
    },
    {
      path: "electricity/:mid",
      name: "fire electricity",
      components: { default: Fire2Electricity, header: DefaultHeader },
    },

    {
      path: "fireproofing/:mid",
      name: "fire proofing",
      components: {
        default: Fire2FireProofingStatistics,
        header: DefaultHeader,
      },
    },
    {
      path: "firefighter/:mid",
      name: "firefighter",
      components: { default: Fire2Firefighter, header: DefaultHeader },
    },
    {
      path: "training/:mid",
      name: "fire training",
      components: { default: Fire2Training, header: DefaultHeader },
    },
    {
      path: "firefighter/community/:mid",
      name: "firefighter",
      components: { default: Fire2FirefighterCommunity, header: DefaultHeader },
    },
    {
      path: "training/community/:mid",
      name: "fire training",
      components: { default: Fire2TrainingCommunity, header: DefaultHeader },
    },
    {
      path: "prevent/:emid",
      name: "fire prevent",
      components: { default: Fire2Prevent, header: DefaultHeader },
    },
    {
      path: "allprocess/:emid",
      name: "fire allprocess",
      components: { default: Fire2AllProcess, header: DefaultHeader },
    },
    {
      path: "allprocesscity/:emid",
      name: "fire allprocess city",
      components: { default: Fire2AllProcessCity, header: DefaultHeader },
    },
    {
      // 免密登录页面
      path: "allprocesscityoutpage",
      name: "fire allprocess city out page",
      components: { default: Fire2AllProcessCity, header: DefaultHeader },
    },
    {
      path: "category/:mid",
      name: "fire category",
      components: { default: Fire2Category, header: DefaultHeader },
    },

    // {
    //     path: 'prevent/community/:emid',
    //     name: 'fire prevent',
    //     components: { default: Fire2PreventCommunity, header: DefaultHeader }
    // },
    // {
    //     path: 'allprocess/community/:emid',
    //     name: 'fire allprocess',
    //     components: { default: Fire2AllProcessCommunity, header: DefaultHeader }
    // },
    {
      path: "tp:mid",
      name: "fire basic template",
      components: { default: Fire2Tp, header: DefaultHeader },
    },
    {
      path: "etp:emid",
      name: "fire evaluation template",
      components: { default: Fire2ETp, header: DefaultHeader },
    },
    {
      path: "dtp:dmid",
      name: "fire dimension template",
      components: { default: Fire2DTp, header: DefaultHeader },
    },
    {
      path: "stp:smid",
      name: "fire station template",
      components: { default: Fire2STp, header: DefaultHeader },
    },
    {
      path: "stp:smid",
      name: "fire station template",
      components: { default: Fire2STp, header: DefaultHeader },
    },

    {
      path: "dimensions/:dmid",
      name: "fire station dimensions",
      components: { default: Fire2Dimensions, header: DefaultHeader },
    },
    {
      path: "stationcost/:smid",
      name: "fire station cost",
      components: { default: Fire2Stationcost, header: DefaultHeader },
    },
    {
      // 免密登录页面
      path: "stationcostoutpage",
      name: "fire station cost out page",
      components: { default: Fire2Stationcost, header: DefaultHeader },
    },
    {
      path: "stationgrid/:smid",
      name: "fire grid",
      components: { default: Fire2StationGrids, header: DefaultHeader },
    },
    {
      // 免密登录页面
      path: "stationgridoutpage",
      name: "fire grid out page",
      components: { default: Fire2StationGrids, header: DefaultHeader },
    },
    {
      path: "stationduration/:smid",
      name: "fire total duration",
      components: { default: Fire2StationDuration, header: DefaultHeader },
    },
    {
      path: "stationlayouts/:smid",
      name: "fire layouts",
      components: { default: Fire2StationLayouts, header: DefaultHeader },
    },
    {
      path: "stationarrive/:smid",
      name: "fire arrive",
      components: { default: StationArrive, header: DefaultHeader },
    },
    {
      // 免密登录页面
      path: "stationarriveoutpage",
      name: "fire arrive out page",
      components: { default: StationArrive, header: DefaultHeader },
    },
    {
      path: "vehicles/:smid",
      name: "fire vehicles",
      components: { default: Fire2Vehicles, header: DefaultHeader },
    },
    {
      // 免密登录页面
      path: "vehiclesoutpage",
      name: "fire vehicles out page",
      components: { default: Fire2Vehicles, header: DefaultHeader },
    },
    {
      path: "equipments/:smid",
      name: "fire equipments",
      components: { default: Fire2Equipments, header: DefaultHeader },
    },
    {
      path: "decisionrisk/:did",
      name: "fire decision risk",
      components: { default: Fire2DecisionRisk, header: DefaultHeader },
    },
    {
      path: "decisionscore/:did",
      name: "fire decision scores",
      components: { default: Fire2DecisionScores, header: DefaultHeader },
    },
    {
      path: "decisionforecast/:did",
      name: "fire decision forecast",
      components: { default: Fire2DecisionForecast, header: DefaultHeader },
    },
    {
      path: "decisionduration/:sid",
      name: "fire decision duration",
      components: { default: Fire2DecisionDuration, header: DefaultHeader },
    },
    {
      path: "decisiontotalduration/:sid",
      name: "fire decision total duration",
      components: {
        default: Fire2DecisionTotalDuration,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionlayoutOptimization/:sid",
      name: "fire decision layout optimization",
      components: {
        default: Fire2DecisionLayoutOptimization,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionlayoutMoreOptimization/:sid",
      name: "fire decision layout more optimization",
      components: {
        default: Fire2DecisionLayoutMoreOptimization,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionlayoutBalanceOptimization/:sid",
      name: "fire decision layout balance optimization",
      components: {
        default: Fire2DecisionLayoutBalanceOptimization,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionlayoutFullCoverOptimization/:sid",
      name: "fire decision layout Full cover optimization",
      components: {
        default: Fire2DecisionLayoutFullCoverOptimization,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionVehicleOptimizations/:vid",
      name: "fire decision vehicle optimization",
      components: {
        default: Fire2DecisionVehicleOptimization,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionPeopleOptimizations/:vid",
      name: "fire decision people optimization",
      components: {
        default: Fire2DecisionPeopleOptimization,
        header: DefaultHeader,
      },
    },
    {
      path: "decisionEquipmentOptimizations/:vid",
      name: "fire decision equipment optimization",
      components: {
        default: Fire2DecisionEquipmentOptimization,
        header: DefaultHeader,
      },
    },

    // {
    //     path: 'alarm',
    //     name: 'fire alarm',
    //     components: { default: FireAlarmStatistics, header: DefaultHeader }
    // },
    // {
    //     path: 'disaster',
    //     name: 'fire disaster',
    //     components: { default: FireDisasterStatistics, header: DefaultHeader }
    // },
    {
      path: "death",
      name: "fire death",
      components: { default: FireDeathStatistics, header: DefaultHeader },
    },
    // {
    //     path: 'injury',
    //     name: 'fire injury',
    //     components: { default: FireInjuryStatistics, header: DefaultHeader }
    // },
    // {
    //     path: 'loss',
    //     name: 'fire loss',
    //     components: { default: FireLossStatistics, header: DefaultHeader }
    // },
    // {
    //     path: 'population',
    //     name: 'fire population',
    //     components: { default: FirePopulation, header: DefaultHeader }
    // },
    // {
    //     path: 'produce',
    //     name: 'fire produce',
    //     components: { default: FireProduce, header: DefaultHeader }
    // },
    // {
    //     path: 'land',
    //     name: 'fire land',
    //     components: { default: FireLand, header: DefaultHeader }
    // },
    // {
    //     path: 'industry',
    //     name: 'fire industry',
    //     components: { default: FireIndustry, header: DefaultHeader }
    // },
    // {
    //     path: 'power',
    //     name: 'fire power',
    //     components: { default: FirePower, header: DefaultHeader }
    // },
    // {
    //     path: 'unitrisk',
    //     name: 'fire unit risk',
    //     components: { default: FireUnitRisk, header: DefaultHeader }
    // },
    // {
    //     path: 'unitkey',
    //     name: 'fire unit key',
    //     components: { default: FireUnitKey, header: DefaultHeader }
    // },
    // {
    //     path: 'test',
    //     name: 'fire unit test',
    //     components: { default: FireTest, header: DefaultHeader }
    // },
    // {
    //     path: 'alarm/history',
    //     name: 'fire history',
    //     components: { default: FireHistory, header: DefaultHeader }
    // },
  ],
};
let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User, header: DefaultHeader },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine, header: DefaultHeader },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home1",
  },
  // {
  //     path: '/refresh',
  //     name: 'refresh',
  //     component: refresh
  // },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  //homeMenu,
  pagesMenu,
  authPages,
  earthquakeBuildingMenu,
  // assessMenu,
  // decisionMenu,
  earthquakeWaterMenu,
  earthquakeTrafficMenu,
  earthquakeElectricMenu,
  {
    path: "/dashboard",
    component: DashboardLayout,
    //redirect: '/dashboard',
    name: "Dashboard1",
    children: [
      {
        path: "/",
        name: "Dashboard",
        components: { default: Dashboard, header: DashboardHeader },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar, header: CalendarHeader },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts, header: DefaultHeader },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets, header: DefaultHeader },
      },
    ],
  },
  fireBuildingMenu,
  { path: "*", component: NotFound },
];

export default routes;
