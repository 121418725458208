<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar v-if="!outPage">
      <template slot-scope="props" slot="links">
        <!-- <user-menu></user-menu> -->
        <sidebar-item ref="aaa" :link="{ id: 1, name: '城市/灾害信息', icon: 'now-ui-icons design_image' }"
          v-if="$route.path.indexOf('earthquake/building') >= 0">
          <sidebar-item :link="{ name: '城市基本信息', path: '/earthquake/building/city-info' }"></sidebar-item>
          <sidebar-item :link="{ name: '灾害基本信息', path: '/earthquake/building/disaster' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ id: 2, name: '基础设施信息', icon: 'now-ui-icons education_paper' }"
          v-if="$route.path.indexOf('earthquake/building') >= 0">
          <sidebar-item :link="{ name: '结构类型', path: '/earthquake/building/struct' }"></sidebar-item>
          <sidebar-item :link="{ name: '功能类型', path: '/earthquake/building/category' }"></sidebar-item>
          <sidebar-item :link="{ name: '抗震设防烈度', path: '/earthquake/building/quake-level' }"></sidebar-item>
          <sidebar-item :link="{ name: '修建年代', path: '/earthquake/building/build-year' }"></sidebar-item>
          <sidebar-item :link="{ name: '重建成本', path: '/earthquake/building/rebuild-cost' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ id: 3, name: '韧性评估', icon: 'now-ui-icons education_atom' }"
          v-if="$route.path.indexOf('earthquake/building') >= 0">
          <sidebar-item :link="{ name: '地震分析', path: '/earthquake/building/quake' }"></sidebar-item>
          <sidebar-item :link="{ name: '物理损伤', path: '/earthquake/building/physical-loss' }"></sidebar-item>
          <sidebar-item :link="{ name: '经济/社会损失', path: '/earthquake/building/economic-loss' }"></sidebar-item>
          <sidebar-item :link="{ name: '综合风险指数', path: '/earthquake/building/comprehensive-risk' }"></sidebar-item>
          <sidebar-item :link="{ name: '功能恢复', path: '/earthquake/building/function-recovery' }"></sidebar-item>
          <sidebar-item :link="{ name: '风险四色图', path: '/earthquake/building/risk' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ id: 4, name: '韧性决策', icon: 'now-ui-icons el-icon-guide' }"
          v-if="$route.path.indexOf('earthquake/building') >= 0">
          <sidebar-item :link="{ name: '加固改造（灾前）', path: '/earthquake/building/reinforce' }"></sidebar-item>
          <sidebar-item :link="{ name: '应急避难（灾后）', path: '/earthquake/building/shelter' }"></sidebar-item>
          <sidebar-item :link="{ name: '医疗救援（灾后）', path: '/earthquake/building/rescue' }"></sidebar-item>
          <sidebar-item :link="{ name: '恢复施工（灾后）', path: '/earthquake/building/recovery' }"></sidebar-item>
        </sidebar-item>





        <sidebar-item :link="{ id: 1, name: '基本信息', icon: 'now-ui-icons design_image' }"
          v-if="$route.path.indexOf('earthquake/water') >= 0">
          <sidebar-item :link="{ name: '总体拓扑', path: '/earthquake/water/basic' }"></sidebar-item>
          <sidebar-item :link="{ name: '节点属性', path: '/earthquake/water/node' }"></sidebar-item>
          <sidebar-item :link="{ name: '管道属性', path: '/earthquake/water/pipe' }"></sidebar-item>
          <sidebar-item :link="{ name: '供水量', path: '/earthquake/water/volumn' }"></sidebar-item>
        </sidebar-item>


        <sidebar-item :link="{ id: 2, name: '韧性评估', icon: 'now-ui-icons education_atom' }"
          v-if="$route.path.indexOf('earthquake/water') >= 0">
          <sidebar-item :link="{ name: '物理损伤', path: '/earthquake/water/physical-loss' }"></sidebar-item>
          <sidebar-item :link="{ name: '功能失效', path: '/earthquake/water/function-loss' }"></sidebar-item>
          <sidebar-item :link="{ name: '重要性系数', path: '/earthquake/water/significance' }"></sidebar-item>
          <sidebar-item :link="{ name: '风险四色图', path: '/earthquake/water/risk' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ id: 3, name: '韧性决策', icon: 'now-ui-icons el-icon-guide' }"
          v-if="$route.path.indexOf('earthquake/water') >= 0">
          <sidebar-item :link="{ name: '加固改造（灾前）', path: '/earthquake/water/reinforce' }"></sidebar-item>
          <sidebar-item :link="{ name: '恢复施工（灾后）', path: '/earthquake/water/recovery' }"></sidebar-item>
        </sidebar-item>



        <sidebar-item :link="{ id: 1, name: '基本信息', icon: 'now-ui-icons design_image' }"
          v-if="$route.path.indexOf('earthquake/electric') >= 0">
          <sidebar-item :link="{ name: '总体拓扑', path: '/earthquake/electric/basic' }"></sidebar-item>
          <sidebar-item :link="{ name: '节点属性', path: '/earthquake/electric/node' }"></sidebar-item>
          <sidebar-item :link="{ name: '线路属性', path: '/earthquake/electric/pipe' }"></sidebar-item>
          <sidebar-item :link="{ name: '用电量', path: '/earthquake/electric/volumn' }"></sidebar-item>
        </sidebar-item>


        <sidebar-item :link="{ id: 2, name: '韧性评估', icon: 'now-ui-icons education_atom' }"
          v-if="$route.path.indexOf('earthquake/electric') >= 0">
          <sidebar-item :link="{ name: '功能失效', path: '/earthquake/electric/function-loss' }"></sidebar-item>
          <sidebar-item :link="{ name: '重要性系数', path: '/earthquake/electric/significance' }"></sidebar-item>
          <sidebar-item :link="{ name: '风险四色图', path: '/earthquake/electric/risk' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ id: 3, name: '韧性决策', icon: 'now-ui-icons el-icon-guide' }"
          v-if="$route.path.indexOf('earthquake/electric') >= 0">
          <sidebar-item :link="{ name: '加固改造（灾前）', path: '/earthquake/electric/reinforce' }"></sidebar-item>
          <sidebar-item :link="{ name: '恢复施工（灾后）', path: '/earthquake/electric/recovery' }"></sidebar-item>
        </sidebar-item>



        <sidebar-item :link="{ id: 1, name: '基本信息', icon: 'now-ui-icons design_image' }"
          v-if="$route.path.indexOf('earthquake/traffic') >= 0">
          <sidebar-item :link="{ name: '总体拓扑', path: '/earthquake/traffic/basic' }"></sidebar-item>
          <!-- <sidebar-item :link="{name: '节点流量', path: '/earthquake/traffic/node'}"></sidebar-item> -->
          <sidebar-item :link="{ name: '道路等级', path: '/earthquake/traffic/pipe' }"></sidebar-item>
          <sidebar-item :link="{ name: '交通流量', path: '/earthquake/traffic/volumn' }"></sidebar-item>
        </sidebar-item>


        <sidebar-item :link="{ id: 2, name: '韧性评估', icon: 'now-ui-icons education_atom' }"
          v-if="$route.path.indexOf('earthquake/traffic') >= 0">
          <sidebar-item :link="{ name: '物理损伤', path: '/earthquake/traffic/physical-loss' }"></sidebar-item>
          <sidebar-item :link="{ name: '拥堵情况', path: '/earthquake/traffic/jam' }"></sidebar-item>
          <sidebar-item :link="{ name: '出行效率', path: '/earthquake/traffic/efficiency' }"></sidebar-item>
          <sidebar-item :link="{ name: '重要性', path: '/earthquake/traffic/significance' }"></sidebar-item>
          <sidebar-item :link="{ name: '风险四色图', path: '/earthquake/traffic/risk' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ id: 3, name: '韧性决策', icon: 'now-ui-icons el-icon-guide' }"
          v-if="$route.path.indexOf('earthquake/traffic') >= 0">
          <sidebar-item :link="{ name: '加固改造（灾前）', path: '/earthquake/traffic/reinforce' }"></sidebar-item>
          <sidebar-item :link="{ name: '恢复施工（灾后）', path: '/earthquake/traffic/recovery' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ id: 1, name: '基本信息', icon: 'now-ui-icons education_paper', mids: ',1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,' }"
          v-if="$route.path.indexOf('fire/building') >= 0">
          <sidebar-item :link="{ name: '历史火灾信息', path: '/fire/building/alarm/1', mids: ',1,2,3,4,5,31,67,68,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,31,67,68,'.indexOf(',' + $route.params.mid + ',') > -1">
            <div :class="($route.params.mid == 1 || $route.params.mid == 31 || $route.params.mid == 67) ? 'cur' : ''"><a
                href="#/fire/building/alarm/1">火警</a></div>
            <div :class="($route.params.mid == 2 || $route.params.mid == 68) ? 'cur' : ''"><a
                href="#/fire/building/tp2">火灾</a></div>
            <div :class="$route.params.mid == 3 ? 'cur' : ''"><a href="#/fire/building/tp3">人员死亡</a></div>
            <div :class="$route.params.mid == 4 ? 'cur' : ''"><a href="#/fire/building/tp4">人员受伤</a></div>
            <div :class="$route.params.mid == 5 ? 'cur' : ''"><a href="#/fire/building/tp5">经济损失</a></div>
          </div>

          <sidebar-item :link="{ name: '区域概况信息', path: '/fire/building/tp6', mids: ',6,7,8,9,10,11,32,69,70,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',6,7,8,9,10,11,32,69,70,'.indexOf(',' + $route.params.mid + ',') > -1">
            <div :class="$route.params.mid == 6 ? 'cur' : ''"><a href="#/fire/building/tp6">人口</a></div>
            <div :class="$route.params.mid == 7 ? 'cur' : ''"><a href="#/fire/building/tp7">生产</a></div>
            <div :class="$route.params.mid == 8 ? 'cur' : ''"><a href="#/fire/building/tp8">建设用地</a></div>
            <div :class="$route.params.mid == 9 ? 'cur' : ''"><a href="#/fire/building/tp9">产业结构</a></div>
            <div :class="$route.params.mid == 10 ? 'cur' : ''"><a href="#/fire/building/electricity/10">用电</a></div>
            <div :class="($route.params.mid == 11 || $route.params.mid == 32) ? 'cur' : ''"><a
                href="#/fire/building/tp11">重要单位</a></div>
            <div :class="($route.params.mid == 69) ? 'cur' : ''"><a href="#/fire/building/tp69">医疗</a></div>
            <div :class="($route.params.mid == 70) ? 'cur' : ''"><a href="#/fire/building/tp70">避难疏散安置</a></div>
          </div>

          <sidebar-item :link="{ name: '典型单位信息', path: '/fire/building/tp12', mids: ',12,13,14,15,16,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',12,13,14,15,16,'.indexOf(',' + $route.params.mid + ',') > -1">
            <div :class="$route.params.mid == 12 ? 'cur' : ''"><a href="#/fire/building/tp12">基本情况</a></div>
            <div :class="$route.params.mid == 13 ? 'cur' : ''"><a href="#/fire/building/fireproofing/13">建筑防火</a></div>
            <div :class="$route.params.mid == 14 ? 'cur' : ''"><a href="#/fire/building/tp14">消防设施设备</a></div>
            <div :class="$route.params.mid == 15 ? 'cur' : ''"><a href="#/fire/building/tp15">消防安全管理</a></div>
            <div :class="$route.params.mid == 16 ? 'cur' : ''"><a href="#/fire/building/tp16">灭火救援</a></div>
          </div>
          <sidebar-item
            :link="{ name: '灭火救援信息', path: '/fire/building/tp17', mids: ',17,18,19,20,21,22,23,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,' }">
          </sidebar-item>
          <div class="sub-item"
            v-if="',17,18,19,20,21,22,23,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,'.indexOf(',' + $route.params.mid + ',') > -1">
            <div
              :class="($route.params.mid == 17 || $route.params.mid == 38 || $route.params.mid == 39 || $route.params.mid == 40 || $route.params.mid == 41 || $route.params.mid == 42) ? 'cur' : ''">
              <a href="#/fire/building/tp17">社会消防力量</a>
            </div>
            <div :class="($route.params.mid == 18 || $route.params.mid == 43 || $route.params.mid == 44) ? 'cur' : ''">
              <a href="#/fire/building/tp18">消防通信</a>
            </div>
            <div :class="($route.params.mid == 19 || $route.params.mid == 45 || $route.params.mid == 46) ? 'cur' : ''">
              <a href="#/fire/building/tp19">消防供水</a>
            </div>
            <div
              :class="($route.params.mid == 20 || $route.params.mid == 47 || $route.params.mid == 48 || $route.params.mid == 49) ? 'cur' : ''">
              <a href="#/fire/building/firefighter/20">消防人员</a>
            </div>
            <div
              :class="($route.params.mid == 21 || $route.params.mid == 50 || $route.params.mid == 51 || $route.params.mid == 52) ? 'cur' : ''">
              <a href="#/fire/building/tp21">消防装备</a>
            </div>
            <div :class="($route.params.mid == 22 || $route.params.mid == 53) ? 'cur' : ''"><a
                href="#/fire/building/tp22">应急预案</a></div>
            <div
              :class="($route.params.mid == 23 || $route.params.mid == 54 || $route.params.mid == 55 || $route.params.mid == 56 || $route.params.mid == 57) ? 'cur' : ''">
              <a href="#/fire/building/tp23">灭火救援时长</a>
            </div>
          </div>

          <sidebar-item
            :link="{ name: '消防管理信息', path: '/fire/building/tp24', mids: ',24,25,26,27,28,29,30,58,59,60,61,62,63,64,65,66,' }">
          </sidebar-item>
          <div class="sub-item"
            v-if="',24,25,26,27,28,29,30,58,59,60,61,62,63,64,65,66,'.indexOf(',' + $route.params.mid + ',') > -1">
            <div :class="($route.params.mid == 24 || $route.params.mid == 58 || $route.params.mid == 59) ? 'cur' : ''">
              <a href="#/fire/building/tp24">消防规划</a>
            </div>
            <div :class="($route.params.mid == 25 || $route.params.mid == 60 || $route.params.mid == 61) ? 'cur' : ''">
              <a href="#/fire/building/training/25">消防宣教培训</a>
            </div>
            <div :class="($route.params.mid == 26 || $route.params.mid == 62) ? 'cur' : ''"><a
                href="#/fire/building/tp26">政府管理</a></div>
            <div :class="($route.params.mid == 27 || $route.params.mid == 63) ? 'cur' : ''"><a
                href="#/fire/building/tp27">消防经费</a></div>
            <div
              :class="($route.params.mid == 28 || $route.params.mid == 64 || $route.params.mid == 65 || $route.params.mid == 66) ? 'cur' : ''">
              <a href="#/fire/building/tp28">消防执法</a>
            </div>
            <div :class="$route.params.mid == 29 ? 'cur' : ''"><a href="#/fire/building/tp29">消防保险</a></div>
            <div :class="$route.params.mid == 30 ? 'cur' : ''"><a href="#/fire/building/tp30">消防监督力量</a></div>
          </div>
        </sidebar-item>

        <sidebar-item
          :link="{ id: 2, name: '韧性评估', icon: 'now-ui-icons education_atom', emids: ',1,2,3,4,5,', dmids: ',1,2,3,4,5,6,7,', smids: ',1,2,3,4,5,6,' }"
          v-if="$route.path.indexOf('fire/building') >= 0">

          <sidebar-item :link="{ name: '按阶段评估', path: '/fire/building/prevent/1', emids: ',1,2,3,4,5,6,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,6,'.indexOf(',' + $route.params.emid + ',') > -1">
            <div :class="($route.params.emid == 1) ? 'cur' : ''"><a href="#/fire/building/prevent/1">预防阶段</a></div>
            <div :class="($route.params.emid == 2) ? 'cur' : ''"><a href="#/fire/building/etp2">减轻阶段</a></div>
            <div :class="($route.params.emid == 3) ? 'cur' : ''"><a href="#/fire/building/etp3">救援阶段</a></div>
            <div :class="($route.params.emid == 4) ? 'cur' : ''"><a href="#/fire/building/etp4">恢复阶段</a></div>
            <div :class="($route.params.emid == 5 || $route.params.emid == 6) ? 'cur' : ''"><a href="#/fire/building/allprocess/5">全阶段</a></div>
          </div>
          <sidebar-item :link="{ name: '按维度评估', path: '/fire/building/dtp1', dmids: ',1,2,3,4,5,6,7,' }"></sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,6,7,'.indexOf(',' + $route.params.dmid + ',') > -1">
            <div :class="($route.params.dmid == 1) ? 'cur' : ''"><a href="#/fire/building/dtp1">社会和保障维度</a></div>
            <div :class="($route.params.dmid == 2) ? 'cur' : ''"><a href="#/fire/building/dtp2">经济和建设维度</a></div>
            <div :class="($route.params.dmid == 3) ? 'cur' : ''"><a href="#/fire/building/dtp3">组织和制度维度</a></div>
            <div :class="($route.params.dmid == 4) ? 'cur' : ''"><a href="#/fire/building/dtp4">工程和技术维度</a></div>
            <div :class="($route.params.dmid == 5) ? 'cur' : ''"><a href="#/fire/building/dtp5">环境和生态维度</a></div>
            <div :class="($route.params.dmid == 6) ? 'cur' : ''"><a href="#/fire/building/dtp6">基础设施维度</a></div>
            <div :class="($route.params.dmid == 7) ? 'cur' : ''"><a href="#/fire/building/dimensions/7">全维度</a></div>
          </div>
          <sidebar-item :link="{ name: '灭火救援专项评估', path: '/fire/building/stationcost/1', smids: ',1,2,3,4,5,6,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,6,'.indexOf(',' + $route.params.smid + ',') > -1">
            <div :class="($route.params.smid == 1) ? 'cur' : ''"><a href="#/fire/building/stationcost/1">消防站价值评估</a>
            </div>
            <div :class="($route.params.smid == 2) ? 'cur' : ''"><a href="#/fire/building/stationgrid/2">消防站辖区划分评估</a>
            </div>
            <div :class="($route.params.smid == 3 || $route.params.smid == 6) ? 'cur' : ''"><a href="#/fire/building/stationlayouts/3">消防站布局评估</a>
            </div>
            <div :class="($route.params.smid == 4) ? 'cur' : ''"><a href="#/fire/building/vehicles/4">消防车辆配备评估</a></div>
            <div :class="($route.params.smid == 5) ? 'cur' : ''"><a href="#/fire/building/equipments/5">消防器材配备评估</a>
            </div>
          </div>
          <!-- <li class="router-link-exact-active">
              <a  class="nav-link router-link-exact-active active" @click="staticToggle"><span class="sidebar-mini-icon">按</span><span class="sidebar-normal">按维度评估</span></a>
            </li>
            <div class="sub-item static-sub-item" v-if="showStaticMenu">
              <div>社会和保障维度</div>
              <div>经济和建设维度</div>
              <div>组织和制度维度</div>
              <div>工程和技术维度</div>
              <div>环境和生态维度</div>
              <div>基础设施维度</div>
              <div>全维度</div>
            </div> -->
        </sidebar-item>
        <sidebar-item
          :link="{ id: 3, name: '韧性决策', icon: 'now-ui-icons el-icon-guide', dids: ',1,2,3,4,5,', sids: ',1,2,3,4,5,6,', vids: ',1,2,3,4,5,' }"
          v-if="$route.path.indexOf('fire/building') >= 0">
          <sidebar-item :link="{ name: '火灾风险预测和防控对策', path: '/fire/building/decisionrisk/1', dids: ',1,2,3,4,5,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,'.indexOf(',' + $route.params.did + ',') > -1">
            <div :class="($route.params.did == 1 || $route.params.did == 3) ? 'cur' : ''"><a href="#/fire/building/decisionrisk/1">未来火灾风险及火灾数据预测</a>
            </div>
            <div :class="($route.params.did == 2) ? 'cur' : ''"><a href="#/fire/building/decisionscore/2">典型单位火灾风险防控对策</a>
            </div>
          </div>

          <sidebar-item
            :link="{ name: '消防人员和装备配备优化', path: '/fire/building/decisionPeopleOptimizations/1', vids: ',1,2,3,4,5,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,'.indexOf(',' + $route.params.vid + ',') > -1">
            <div :class="($route.params.vid == 1) ? 'cur' : ''"><a
                href="#/fire/building/decisionPeopleOptimizations/1">消防人员配备优化</a></div>
            <div :class="($route.params.vid == 2) ? 'cur' : ''"><a
                href="#/fire/building/decisionEquipmentOptimizations/2">消防器材配备优化</a></div>
            <div :class="($route.params.vid == 3) ? 'cur' : ''"><a
                href="#/fire/building/decisionVehicleOptimizations/3">消防车辆配备优化</a></div>
          </div>
          <sidebar-item
            :link="{ name: '消防站辖区划分和布局优化', path: '/fire/building/decisionduration/1', sids: ',1,2,3,4,5,6,' }">
          </sidebar-item>
          <div class="sub-item" v-if="',1,2,3,4,5,6,'.indexOf(',' + $route.params.sid + ',') > -1">
            <div :class="($route.params.sid == 1 || $route.params.sid == 2) ? 'cur' : ''"><a
                href="#/fire/building/decisionduration/1">消防站辖区划分优化</a></div>
            <div
              :class="($route.params.sid == 3 || $route.params.sid == 4 || $route.params.sid == 5 || $route.params.sid == 6) ? 'cur' : ''">
              <a href="#/fire/building/decisionlayoutOptimization/3">消防站布局优化</a>
            </div>
          </div>
        </sidebar-item>


        <!-- <sidebar-item :link="{name: 'Pages', icon: 'now-ui-icons design_image'}">
          <sidebar-item :link="{name: 'Pricing', path: '/pricing'}"></sidebar-item>
          <sidebar-item :link="{name: 'Timeline', path: '/pages/timeline'}"></sidebar-item>
          <sidebar-item :link="{name: 'Login', path: '/login'}"></sidebar-item>
          <sidebar-item :link="{name: 'Register', path: '/register'}"></sidebar-item>
          <sidebar-item :link="{name: 'Lock Screen', path: '/lock'}"></sidebar-item>
          <sidebar-item :link="{name: 'User Profile', path: '/pages/user'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Components', icon: 'now-ui-icons education_atom'}">
          <sidebar-item :link="{name: 'Buttons', path: '/components/buttons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Grid System', path: '/components/grid-system'}"></sidebar-item>
          <sidebar-item :link="{name: 'Panels', path: '/components/panels'}"></sidebar-item>
          <sidebar-item :link="{name: 'Sweet Alert', path: '/components/sweet-alert'}"></sidebar-item>
          <sidebar-item :link="{name: 'Notifications', path: '/components/notifications'}"></sidebar-item>
          <sidebar-item :link="{name: 'Icons', path: '/components/icons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Typography', path: '/components/typography'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Forms', icon: 'now-ui-icons files_single-copy-04'}">
          <sidebar-item :link="{name: 'Regular Forms', path: '/forms/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Forms', path: '/forms/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Validation Forms', path: '/forms/validation'}"></sidebar-item>
          <sidebar-item :link="{name: 'Wizard', path: '/forms/wizard'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Tables', icon: 'now-ui-icons design_bullet-list-67'}">
          <sidebar-item :link="{name: 'Regular Tables', path: '/table-list/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Tables', path: '/table-list/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Paginated Tables', path: '/table-list/paginated'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Maps', icon: 'now-ui-icons location_pin'}">
          <sidebar-item :link="{name: 'Google Maps', path: '/maps/google'}"></sidebar-item>
          <sidebar-item :link="{name: 'Full Screen Maps', path: '/maps/full-screen'}"></sidebar-item>
          <sidebar-item :link="{name: 'Vector Maps', path: '/maps/vector-map'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: 'Widgets', icon: 'now-ui-icons objects_diamond', path: '/widgets'}"></sidebar-item>
        <sidebar-item
          :link="{name: 'Charts', icon: 'now-ui-icons business_chart-pie-36', path: '/charts'}"></sidebar-item>
        <sidebar-item
          :link="{name: 'Calendar', icon: 'now-ui-icons media-1_album', path: '/calendar'}"></sidebar-item> -->


      </template>
    </side-bar>
    <div class="main-panel" :class="{ 'main-panel-full': outPage }">
      <top-navbar v-if="!outPage"></top-navbar>
      <router-view v-if="!outPage" name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <router-view></router-view>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { consts } from "src/util/consts.js";
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './Extra/MobileMenu.vue';
import UserMenu from './Extra/UserMenu.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

export default {
  data() {
    return {
      showStaticMenu: false,
      outPage: consts.outPages.includes(this.$route.path),
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    SlideYDownTransition,
    ZoomCenterTransition,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapsedOthers() {

    },
    staticToggle() {
      //静态写入的菜单折叠展开
      this.showStaticMenu = !this.showStaticMenu

    }
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar');
      initScrollbar('sidebar-wrapper');
      initScrollbar('tab-scroller');


      docClasses.add('perfect-scrollbar-on');
    } else {
      docClasses.add('perfect-scrollbar-off');
    }
  }
};
</script>
<style lang="scss">
.main-panel-full {
  width: 100% !important;
  height: 100% !important;
  .card-map {
    height: 100vh !important;
    #map {
      height: calc(100vh - 50px) !important;
      &.map-full {
        height: 100vh !important;
      }
    }
  }
  #customStyle {
    height: calc(100vh - 3px) !important;
  }
}
// $scaleSize: 0.95;
// @keyframes zoomIn95 {
//   from {
//     opacity: 0;
//     transform: scale3d($scaleSize, $scaleSize, $scaleSize);
//   }
//   to {
//     opacity: 1;
//   }
// }
// .main-panel .zoomIn {
//   animation-name: zoomIn95;
// }
// @keyframes zoomOut95 {
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//     transform: scale3d($scaleSize, $scaleSize, $scaleSize);
//   }
// }
// .main-panel .zoomOut {
//   animation-name: zoomOut95;
// }
</style>
