import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./dashboard-plugin";
// import echarts from 'echarts';
import * as echarts from "echarts";

// Plugins
import App from "./App.vue";

// router setup
import routes from "./routes/routes";
import { consts } from "src/util/consts.js";

import { HappyScroll } from "vue-happy-scroll";
Vue.component("happy-scroll", HappyScroll);
import "vue-happy-scroll/docs/happy-scroll.css";

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.prototype.$echarts = echarts;
// configure router

// // 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)

// }

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (consts.outPages.includes(to.path)) {
    // 对外免密页面
    next();
    return
  }
  if (to.path != "/dashboard") {
    if (document.querySelector(".wrapper")) {
      document.querySelector(".wrapper").classList.remove("dashboard");
    }
  }
  if (to.path === "/login") {
    next();
  } else {
    let token = sessionStorage.getItem("AccessToken");

    if (token === null || token === "") {
      next("/login");
    } else {
      next();
    }
  }
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
});
