class consts {
  // 免密登录页面
  static outPages = [
    "/fire/building/allprocesscityoutpage",
    "/fire/building/stationarriveoutpage",
    "/fire/building/stationcostoutpage",
    "/fire/building/stationgridoutpage",
    "/fire/building/vehiclesoutpage",
  ]
  static buildingStructs = [
    "结构类型",
    "砖混结构",
    "钢筋混凝土框架结构",
    "钢筋混凝土框架剪力墙",
    "钢筋混凝土剪力墙结构",
    "钢结构",
    "木结构",
  ];
  static buildingCategories = [
    "功能类型",
    "居住建筑",
    "商用建筑",
    "行政单位",
    "交通出行",
    "教育机构",
    "医疗机构",
    "旅游景点",
    "工业建筑",
  ];
  static buildingStructsFire = [
    "建筑结构",
    "砖混结构",
    "钢筋混凝土框架结构",
    "钢筋混凝土框架剪力墙",
    "钢筋混凝土剪力墙结构",
    "钢结构",
    "木结构",
  ];
  static buildingCategoriesFire = [
    "单位性质",
    "住宅",
    "公众聚集场所",
    "医疗机构",
    "教育机构",
    "国家机关单位",
    "交通设施",
    "文物建筑",
    "厂房",
    "仓库",
    "高层办公、公寓",
    "地下公共建筑",
    "其他",
  ];
  static buildingBuildYears = [
    "修建年代",
    "1960-1969",
    "1970-1979",
    "1980-1989",
    "1990-1999",
    "2000-2010",
  ];
  static buildingRebuildCosts = [
    "重建成本",
    "10万以下",
    "10万-100万",
    "100万-1000万",
    "1000万-1亿",
    "大于1亿",
  ];

  static fireForecastDict = {
    "time": [
      200612,
      202211
    ],
    "dist": [
      "上城区",
      "拱墅区",
      "西湖区",
      "滨江区",
      "风景名胜区"
    ],
    "caus": [
      "玩火",
      "燃放烟花爆竹",
      "自燃",
      "雷击",
      "静电",
      "遗留火种",
      "不排除原因",
      "其他",
      "防火",
      "电气火灾",
      "生产作业",
      "用火不慎",
      "吸烟",
    ],
    "scene": [
      "居住场所",
      "办公场所",
      "学校",
      "商业场所",
      "宾馆、饭店、招待所",
      "纯餐饮场所",
      "公共娱乐场所",
      "交通枢纽（站）",
      "宗教场所",
      "物资仓储场所",
      "厂房",
      "汽车库",
      "修车库",
      "工地",
      "室内农副业场所",
      "其他",
      "非建构筑物",
      "交通工具",
      "垃圾及废弃物",
    ]
  }

  static requestUrl = "https://rencity-back.renkedata.com/";

  static mapCenter = [[120.16436923011929, 30.228062164606342]];
  static mapQuakeMapCenter = [[120.22356392098163, 30.20203031418582]];
  static mapDefaultZoom = 12.7;
  static quakeLocation = [120.297, 30.164]; //地震中心

  //static mapboxAccessToken = 'pk.eyJ1IjoicmVuY2l0eSIsImEiOiJjazhhODlhdGUwZjVnM21wa3NxMjE0ZWZjIn0.xtgqdWXPsc5OBofzxs4B5w';
  //static mapBoxStyle = 'mapbox://styles/mapbox/light-v10';
  //static mapBoxStyle = 'mapbox://styles/mapbox/dark-v10';
  //static mapBoxStyle = 'mapbox://styles/mapbox/dark-v9'; //v10 没有中文
  static mapboxAccessToken =
    "pk.eyJ1IjoibmlraWxpYW4iLCJhIjoiY2tiM280a3hiMGM5YzJ2bHY5MWJrbTd1NCJ9.BUZrfkn3E33BItUVgxCXfg";
  static mapBoxStyle = "mapbox://styles/colaplate/ckahnlp05041f1iqwbg4a0rbr";

  //static geojson_host = 'http://urban-api.olive-app.com';
  //static geojson_host = 'http://rencity-back.renkedata.com';
  static geojson_host = "./";
  static url_geojson_hospital = this.geojson_host + "/geojson/hospital.json";
  static url_geojson_warehouse = this.geojson_host + "/geojson/warehouse.json";
  static url_geojson_hideaway = this.geojson_host + "/geojson/hideaway.json";
  static last_updated = "2020-05-25 10:00:00";
  static url_geojson_quake_history =
    this.geojson_host + "/geojson/quake-history.json";
  static url_geojson_quake_region =
    this.geojson_host + "/geojson/quake-region.json";
  static url_geojson_chasm = this.geojson_host + "/geojson/chasm.json";
  static url_geojson_soil = this.geojson_host + "/geojson/soil.json";

  static historyQuakeLevels = [
    "震级",
    "小于4.0",
    "4.0-5.0",
    "5.0-6.0",
    "6.0-7.0",
    "7.0以上",
  ];
  static historyQuakeYears = [
    "年代",
    "1000以前",
    "1000-1200",
    "1200-1400",
    "1400-1600",
    "1600-1800",
    "1800-2000",
  ];
  static quakeHighestLevels = [
    { id: 0, name: "震级上限" },
    { id: 5.5, name: 5.5 },
    { id: 6.0, name: 6.0 },
    { id: 6.5, name: 6.5 },
    { id: 7.0, name: 7.0 },
  ];

  static defaultLineWidth = 3;
  static defaultCircleRadius = 6;

  static tipsCityInfoPeople =
    "社区人口性别比定义：每个社区中的男性人口相对女性人口的比值";
  static tipsCityInfoHideaway =
    "避难所定义：灾后可对受灾人口进行临时安置的场地，一般为学校、体育馆等公共建筑";
  static tipsCityInfoHospital =
    "医疗救助点定义：灾后可对受伤人口进行医疗救助的地点，一般为现有医院";
  static tipsCityInfoWarehouse =
    "应急储备仓库定义：贮备各类应急物资的场所，城市可通过它可在灾后迅速调配应急所需的各种物资";

  static tipsDisasterQuake = "历史地震记录：历史上发生在周边地区的地震事件。";
  static tipsDisasterChasm =
    "断层定义：地壳受力发生断裂，沿断裂面两侧岩块发生的显著相对位移的构造，主要可分为正断层、逆断层、平推断层等";
  static tipsDisasterRegion =
    "潜在震源区划定义：根据第四纪活动断层的特征、规模大小、活动强度、地震活动性和地球物理场结构等划分的，未来可能发生破坏性地震的地区。潜在震源区的震级上限是指该潜在震源区可能发生地震的上限震级";
  static tipsDisasterSoil =
    "场地土定义：根据土层等效剪切波速和场地覆盖层厚度确定建筑场地类别。场地土类别反应了基岩上土层对地震作用的放大作用。";

  static tipsStruct =
    "结构类型定义：按照建筑的主要受力结构划分的建筑类型，主要分为砖混结构、钢筋混凝土框架结构、钢筋混凝土框架剪力墙结构、钢筋混凝土剪力墙结构、钢结构和木结构。";
  static tipsCategory =
    "功能类型定义：主要按照建筑服务或满足的社会功能划分的建筑类型，主要分为居住建筑、商用建筑、行政单位、交通出行、教育机构、医疗机构、旅游景点和工业建筑。";
  static tipsQuakeLevel =
    "抗震设防烈度定义：按国家规定的权限批准作为一个地区抗震设防的地震烈度称为抗震设防烈度。一般情况下，抗震设防烈度可采用中国地震参数区划图的地震基本烈度。";
  static tipsBuildYear = "修建年代定义：建筑初始建造的年代。";
  static tipsRebuildCost =
    "重建成本定义：以当前的社会经济条件，完全重建建筑所需要话费的成本";

  static tipsPga =
    "PGA定义：PGA即为峰值地震加速度（单位：m/s2），表示了在单次地震波作用过程中，地面的最大加速度，是地震工程中最常使用的表征场地地震强度的指标。PGA常常用来作为地震下结构反应分析的输入条件。";

  static tipsPhyicalLoss =
    "结构构件损伤定义：梁、柱、剪力墙等结构受力构件的物理损伤状态，共分为五个状态：没有（None），轻微（Slight），中等（Moderate），严重（Extensive），完全（Complete）";
  static tipsUnPhyicalLoss =
    "非结构构件损伤定义：建筑结构中除承重受力构件体系以外的固定构件和部件，主要包括非承重墙体、附着于楼屋面结构的构件、装饰构件和部件、固定于楼面的大型储物柜等。";

  static tipsEcnomicLoss =
    "直接经济损伤定义：由于房屋结构构件构件和非结构构件破坏的造成的维修成本，不含由于建筑物功能损失造成的简介损失。";
  static tipsEcnomicLossRate =
    "直接经济损伤率定义：由于房屋结构构件构件和非结构构件破坏的造成的维修成本相对于房屋建筑的重建成本的比值。";
  static tipsDeathMorning =
    "人口伤亡定义：计算统计了以社区为单位由于房屋破坏直接造成的人员伤亡，包含了四个伤亡级别，即轻微，中等，严重，死亡。";
  static tipsDeathAfternoon =
    "人口伤亡定义：计算统计了以社区为单位由于房屋破坏直接造成的人员伤亡，包含了四个伤亡级别，即轻微，中等，严重，死亡。";
  static tipsAwayFromHome =
    "离家住户数定义：计算统计了以社区为单位由于房屋破坏造成住户不能继续居住而需要避灾安置的住户数量。";
  static tipsAwayFromHomeRate =
    "离家住户率定义：计算统计了以社区为单位离家住户数相对总住户数的比值。";
  static tipsFunctionLosses =
    "功能损失定义：综合考虑建筑物的结构构件和非结构构件的损伤，从内部使用者的角度确定的建筑物在灾后的状态。";

  static tipsRisk =
    "综合风险指数定义：给出了考虑结构功能类型，重要性，灾后经济、社会损失和功能恢复过程等因素后得到的，表征了房屋建筑综合风险程度。";

  static tipsRecoveryShow =
    "本页面给出了建筑群落在灾害发生后的功能恢复过程，包括了5个功能状态：RE不能进入 ，RU不能使用，RO可以进入，BF基本能用，FF完全能用。";
  static tipsRecoveryTime =
    "本页面给出了建筑群落在灾害发生后完全恢复功能（FF完全能用状态）所需要的时间。";

  static tipsBeforeReinforce =
    "本页面给出了根据建筑的破坏后造成的经济损失、人口伤亡、功能损失等评估结果确定的在灾前进行抗震加固的优先级。";
  static tipsAfterReinforce =
    "用户可通过滑动加固比例滑块，查看在不同加固比例下整个城市在各经济、社会损失指标上的减损情况，包括经济损失、人员伤亡、离家住户和灾后恢复时间，从而确定最优加固比例。";

  static tipsShelter =
    "用户可根据实际情况，选择可用的避难所，系统将给出离家住户到避难所的最优分配方案。";

  static tipsRescueSerious =
    "重伤救援：将韧性评估得到的“严重”和“死亡”伤亡人数，以小区为单位，按照到医疗点（仅综合医院）的最短路线规划得到的最优分配方案";
  static tipsRescueSlight =
    "用户可根据实际情况，选择可用的医疗点，系统将给出轻伤人口到医疗点的最优分配方案。";

  static tipsRecoveryBuilding =
    "用户可根据实际情况，选择施工总人数，系统将给出各个建筑在各个恢复时间的施工状态，即未修、正修和已修三个状态。";
  static tipsRecoveryCommunities =
    "用户可根据实际情况，选择施工总人数，系统将给出各个小区在各个恢复时间的施工状态，即未修、正修和已修三个状态。";
  static tipsResourceDistribution =
    "用户可根据实际情况，选择施工总人数，系统将给出施工工人在各个恢复时间的空间分布情况。";

  static tipsRecoveryBuildingNew =
    "用户可根据实际情况，选择施工总人数，系统将给出各个建筑在各个恢复时间的施工状态，即未修、正修和完好三个状态。";
  static tipsRecoveryCommunitiesNew =
    "用户可根据实际情况，选择施工总人数，系统将给出各个小区在各个恢复时间的施工状态，即未修、正修和完好三个状态。";
  static tipsResourceDistributionNew =
    "用户可根据实际情况，选择施工总人数，系统将给出施工工人在各个恢复时间的空间分布情况。";

  static tipsWaterBasic = "水网总体拓扑：包含供水网络节点、管线和服务区信息。";
  static tipsWaterNode1 =
    "节点类型定义： 按节点物理信息的功能进行分类，主要分为源点、泵站、用户、水塔/水池。";
  static tipsWaterNode2 =
    "节点高程定义：该节点沿铅垂线方向到绝对基面的距离，绝对基面是指1956黄海高程基准-0.029作为统一基面。";
  static tipsWaterPipe1 = "管道直径：是指供水管道的内径尺寸，单位为m。";
  static tipsWaterPipe2 = "管道材质：是指供水管道所用的材料。";
  static tipsWaterVolumn =
    "用水量：指社区内所有用户的用水量总和，单位为m³/天。";
  static tipsWaterPhysicalLoss =
    "管道物理可靠度：管道在情景地震作用下不发生物理破坏的概率。";
  static tipsWaterFunctionLoss1 =
    "节点功能可靠度：节点在情景地震作用下可正常满足供电功能要求的概率。";
  static tipsWaterFunctionLoss2 =
    "社区供水率：是指社区在灾后的供水能力与灾前供水能力的比值。";
  static tipsWaterImportance =
    "重要性系数：根据管道流量大小、直径大小及影响人群给出管道的重要性系数。";
  static tipsWaterReinforce =
    "管道加固优先级：根据管道流量、直径和物理可靠性等综合评定的灾前抗震加固的优先级。";
  static tipsWaterRecovery1 =
    "用户可根据实际情况，先选择施工队数量，然后系统将给出各管道、节点在地震灾后各个修复时间点的施工状态，即未修、正修和完好三个状态，并区分不同的施工队信息。";
  static tipsWaterRecovery2 =
    "本页面给出来了供水网络在设定的情景地震发生后的功能恢复情况，由社区为单位，以社区供水比率和影响人口比例为功能指标来对功能恢复情况进行展示。";

  static tipsElectricBasic = "电网总体拓扑：包含电网节点、线路和服务区信息。";
  static tipsElectricNode1 =
    "节点类型：按节点物理信息的功能进行分类，主要分为电厂、变电站、交叉点和用户节点。";
  static tipsElectricNode2 = "节点电压：节点的电压等级。";
  static tipsElectricPipe = "线路电压：线路的电压。";
  static tipsElectricVolumn = "用电量：指社区内所有用户所使用的电量。";
  static tipsElectricFunctionLoss1 =
    "节点功能可靠度：节点满足正常供电要求的概率。";
  static tipsElectricFunctionLoss2 =
    "社区供电率：是指灾后社区供电能力与灾前供电能力的比值。";
  static tipsElectricImportance =
    "重要性系数：根据节点的电压等级和影响人群给出。";
  static tipsElectricReinforce =
    "管道加固优先级：本页给出了根据管道节点的电压等级、节点物理可靠性等评估结果综合确定的在灾前进行抗震加固的优先级别。";
  static tipsElectricRecovery1 =
    "用户可根据实际情况，先选择施工队数量，然后系统将给出各节点在各个恢复时间的施工状态，即未修、正修和完好三个状态，并区分不同的施工队信息。";
  static tipsElectricRecovery2 =
    "本页面给出来了供电网络在设定的情景地震发生后的功能恢复情况，由社区为单位，以社区供电率为标准来对功能恢复情况进行展示。";

  static tipsTrafficBasic =
    "交通总体拓扑：包含交通网络的节点、道路和交通小区的空间分布信息。";
  static tipsTrafficPipe = "道路级别：包括了一级道路、二级道路和三级道路。";
  static tipsTrafficNodeVolumn =
    "节点流量：包括节点的进（出）流量，单位为辆/小时。";
  static tipsTrafficVolumn =
    "交通小区流量：指交通小区的进（出）流量，单位为辆/小时。";
  static tipsTrafficRoadlVolumn =
    "道路流量：指道路的进（出）流量，单位为辆/小时。";
  static tipsTrafficJam =
    "道路拥堵情况：道路的拥堵情况共分为四种状态：严重拥堵、中度拥堵、轻微拥堵和通畅。";
  static tipsTrafficPhysicalLoss =
    "道路的物理损伤：道路的物理损伤状态共分为四种状态：严重损伤、中等损伤、轻微损伤和没有损伤。";
  static tipsTrafficEfficiency1 =
    "交通小区出行效率：交通小区在灾前的总出行时间和在灾后的总通行时间之比。";
  static tipsTrafficEfficiency2 =
    "社区出行效率：社区在灾前的总出行时间和灾后的总通行时间之比。";
  static tipsTrafficImportance =
    "重要性系数：根据道路的流量和影响人口综合评定。";
  static tipsTrafficReinforce =
    "道路加固优先级：根据道路的交通流量、道路的物理可靠性、道路功能可靠性等评估结果综合确定的在灾前进行抗震加固的优先级别。";
  static tipsTrafficRecovery1 =
    "用户可根据实际情况，先选择施工队数量，然后系统将给出各道路在地震灾后各个修复时间点的施工状态，即未修、正修和完好三个状态，并区分不同的施工队信息。";
  static tipsTrafficRecovery2 =
    "本页面给出了交通网络在设定的情景地震发生后的功能恢复过程，以社区为单位，从社区通行效率和道路通行指标为功能指标来对功能恢复情况进行展示。";

  static tipsBuildingRisk =
    "本页给出了情景灾害发生后的根据建筑群落的破坏严重程度和划分的风险等级。";
  static tipsWaterRisk =
    "本页给出了情景灾害发生后的根据供水网络的破坏严重程度和划分的风险等级。";
  static tipsElectricRisk =
    "本页给出了情景灾害发生后的根据供电网络的破坏严重程度和划分的风险等级。";
  static tipsTrafficRisk =
    "本页给出了情景灾害发生后的根据交通网络的破坏严重程度和划分的风险等级。";

  //export
  static exportCityInfoPeople = "api/community-populations/export";
  static exportCityInfoHideaway = "api/hideaways/export";
  static exportCityInfoHospital = "api/hospitals/export";
  static exportCityInfoWarehouse = "api/warehouses/export";

  static exportDisasterQuake = "api/quakes/export";
  static exportDisasterChasm = "api/quake-chasms/export";
  static exportDisasterRegion = "api/quake-regions/export";
  static exportDisasterSoil = "api/soils/export";

  static exportStruct = "api/exports/building-struct";
  static exportCategory = "api/exports/building-category";
  static exportQuakeLevel = "api/exports/building-quake-level";
  static exportBuildYear = "api/exports/building-year";
  static exportRebuildCost = "api/exports/building-rebuild-cost";

  static exportPga = "api/exports/building-pga";

  static exportPhyicalLoss = "api/structural-damages/export";
  static exportUnPhyicalLoss = "api/non-structural-damages/export";

  static exportEcnomicLoss = "api/economic-losses/export";
  static exportEcnomicLossRate = "api/economic-loss-rates/export";
  static exportDeathMorning = "api/morning-casualties/export";
  static exportDeathAfternoon = "api/afternoon-casualties/export";
  static exportAwayFromHome = "api/leave-homes/export";
  static exportAwayFromHomeRate = "api/leave-home-rates/export";
  static exportFunctionLosses = "api/functional-status/export";

  static exportRisk = "api/comprehensive-risks/export";

  static exportRecoveryShow = "api/functional-recovery-deductions/export";
  static exportRecoveryShowNew = "api/building-function-recoveries/export";
  static exportRecoveryTime = "api/functional-recovery-times/export";

  static exportBeforeReinforce = "api/reinforcement-priorities/export";
  static exportAfterReinforce = "api/reinforcement-effects/export";

  static exportShelter = "api/shelters/export";

  static exportRescueSerious = "api/serious-injuries/export";
  static exportRescueSlight = "api/minor-injuries/export";

  static exportRecoveryBuilding = "api/building-restorations/export";
  static exportRecoveryCommunities = "api/community-restorations/export";
  static exportRecoveryCommunitiesNew = "api/building-village-repairs/export";
  static exportResourceDistribution = "api/maintenance-resources/export";
  static exportResourceDistributionNew =
    "api/building-village-resources/export";

  static exportBuildingRisk = "api/building-street-risks/export";

  //water 2.1.1
  static exportWaterBasic1 = "api/waters/export-node";
  static exportWaterBasic2 = "api/waters/export-line";
  static exportWaterBasic3 = "api/waters/export-zone";

  //water 2.1.2
  static exportWaterNodeType = "api/water-node-types/export";
  static exportWaterNodeElevation = "api/water-node-elevations/export";

  //water 2.1.3
  static exportWaterPipeDiam = "api/water-line-diameters/export";
  static exportWaterPipeType = "api/water-line-materials/export";

  //water 2.1.4
  static exportWaterVolumn = "api/water-zones/export";

  //water 2.2.1
  static exportWaterPhysicallossNode = "api/water-node-damages/export";
  static exportWaterPhysicallossPipe = "api/water-line-damages/export";

  //water 2.2.2
  static exportWaterFunctionslossNode = "api/water-node-reliabilities/export";
  static exportWaterFunctionslossBlock = "api/water-community-ratios/export";

  //water 2.2.3
  static exportWaterSignificance = "api/water-line-importances/export";

  //water 2.2.4
  static exportWaterRisk = "api/water-street-risks/export";

  //water 2.3.1
  static exportWaterReinforce = "api/water-line-reinforcements/export";

  //water 2.3.2
  static exportWaterRepairsPipe = "api/water-line-repairs/export";
  static exportWaterRepairsNode = "api/water-node-repairs/export";

  static exportWaterFunctionArea = "api/water-community-functions/export";
  static exportWaterFunctionNode = "api/water-node-functions/export";

  //electric 2.1.1
  static exportElectricBasic1 = "api/powers/export-node";
  static exportElectricBasic2 = "api/powers/export-line";
  static exportElectricBasic3 = "api/powers/export-zone";

  //electric 2.1.2
  static exportElectricNodeType = "api/power-node-types/export";
  static exportElectricNodeVoltage = "api/power-node-voltages/export";

  //electric 2.1.3
  static exportElectricPipeVoltage = "api/power-line-voltages/export";

  //electric 2.1.4
  static exportElectricVolumn = "api/power-zones/export";

  //electric 2.2.1
  static exportElectricFunctionslossNode =
    "api/power-node-reliabilities/export";
  static exportElectricFunctionslossBlock = "api/power-community-ratios/export";

  //electric 2.2.2
  static exportElectricSignificance = "api/power-node-importances/export";

  //electric 2.2.3
  static exportElectricRisk = "api/power-street-risks/export";

  //electric 2.3.1
  static exportElectricReinforce = "api/power-node-reinforcements/export";

  //electric 2.3.2
  static exportExportRepairsNode = "api/power-node-repairs/export";

  static exportExportFunctionArea = "api/power-community-functions/export";
  static exportExportFunctionNode = "api/power-node-functions/export";

  //traffic 2.1.1
  static exportTrafficBasic1 = "api/traffics/export-node";
  static exportTrafficBasic2 = "api/traffics/export-line";
  static exportTrafficBasic3 = "api/traffics/export-zone";

  //traffic 2.1.2
  static exportTrafficNodeType = "api/traffic-node-flows/export";

  //traffic 2.1.3
  static exportTrafficPipeType = "api/traffic-line-levels/export";

  //traffic 2.1.4
  static exportTrafficVolumn = "api/traffic-zone-flows/export";
  static exportTrafficPipeVolumn = "api/traffic-line-flows/export";

  //traffic 2.2.1
  static exportTrafficPhysicallossPipe = "api/traffic-line-damages/export";

  //traffic 2.2.2
  static exportTrafficJam = "api/traffic-line-jams/export";

  //traffic 2.2.3
  static exportZoneEfficiency = "api/traffic-zone-travel-efficiencies/export";
  static exportCommunityEfficiency =
    "api/traffic-community-travel-efficiencies/export";

  //traffic 2.2.4
  static exportTrafficSignificance = "api/traffic-line-importances/export";

  //traffic 2.2.5
  static exportTrafficRisk = "api/traffic-street-risks/export";

  //traffic 2.3.1
  static exportTrafficReinforce = "api/traffic-line-reinforcements/export";

  //traffic 2.3.2
  static exportTrafficRepairPipe = "api/traffic-line-repairs/export";

  static exportTrafficFunctionArea = "api/traffic-community-travels/export";
  static exportTrafficFunctionPipe = "api/traffic-line-travels/export";

  //fire 1.1.1
  static exportFireAlarm = "api/fire-alarm-streets/export";
  static exportFireDisaster = "api/fire-disaster-streets/export";
  static exportFireDeath = "api/fire-death-streets/export";
  static exportFireInjury = "api/fire-injury-streets/export";
  static exportFireLoss = "api/fire-loss-streets/export";

  static exportFirePopulation = "api/fire-population-streets/export";
  static exportFireProduce = "api/fire-produce-streets/export";
  static exportFireLand = "api/fire-land-streets/export";
  static exportFireIndustry = "api/fire-industry-streets/export";
  static exportFirePower = "api/fire-power-streets/export";
  static exportFireUnitRisk = "api/fire-unit-risk-streets/export";
  static exportFireUnitKey = "api/fire-unit-key-streets/export";

  //fire2
  static exportFire2Alarm = "api/fire-basics/export";
  static exportFire2Station = "api/fire-stations/export";
  static exportFire2Hall = "api/fire-halls/export";
  static exportFire2Evaluation = "api/fire-evaluations/export";
  static exportFireCategory = "api/exports/building-nature";
  static exportFire2StationCost = "api/fire-station-costs/export";
  static exportFire2StationGrid = "api/fire-station-grids/export";
  static exportFire2StationDuration = "api/fire-station-total-durations/export";
  static exportFire2StationLayouts = "api/fire-station-layouts/export";
  static exportFire2Vehicles = "api/fire-vehicles/export";
  static exportFire2Equipments = "api/fire-equipments/export";
  static exportFire2Dimensions = "api/fire-dimensions/export";
  static exportFire2DecisionRisk = "api/fire-decision-risks/export";
  static exportFire2DecisionScores = "api/fire-decision-scores/export";
  static exportFire2DecisionDuration = "api/fire-decision-durations/export";
  static exportFire2DecisionTotalDuration =
    "api/fire-decision-total-durations/export";
  static exportFire2DecisionOptimizationOnes =
    "api/fire-decision-layout-optimization-ones/export";
  static exportFire2DecisionOptimizationMores =
    "api/fire-decision-layout-optimization-mores/export";
  static exportFire2DecisionOptimizationFullCover =
    "api/fire-decision-layout-optimization-full-coverages/export";
  static exportFire2DecisionOptimizationBalance =
    "api/fire-decision-layout-optimization-balances/export";
  static exportFire2DecisionVehicleOptimization =
    "api/fire-decision-vehicle-optimizations/export";
  static exportFire2DecisionPersonnelOptimization =
    "api/fire-decision-personnel-optimizations/export";
  static exportFire2DecisionEquipmentOptimization =
    "api/fire-decision-equipment-optimizations/export";
}

export { consts };
